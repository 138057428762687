import { Endpoint } from './endpoints';

import ApiClient from './api-client';
import Utils from '~commons/utils';

class RestfullServices {
  constructor() {
    this.apiClient = new ApiClient({
      baseURL: process.env.REACT_APP_API_URL,
      pathPrefix: process.env.REACT_APP_PATH_PREFIX,
      logOutput: true,
    });
  }
  // Account Api
  getStaticVariables = () => this.apiClient.get(Endpoint.staticVariables);
  login = ({ code, password }) => this.apiClient.post(Endpoint.login, { code, password });
  // Customer
  getCustomer = (data = null) => this.apiClient.get(Endpoint.customer, data);
  getCustomerForUILog = (data = null) =>
    this.apiClient.get(Endpoint.customer + `/GetUnilogs`, data);
  //
  getCustomerForVendor = (data = null) =>
    this.apiClient.get(Endpoint.customer + `/GetListAssigned`, data);

  getCustomerDetails = (customerId, data = null) =>
    this.apiClient.get(Endpoint.customer + `/GetDetails/${customerId}`, data);
  getCustomers = (data = null) =>
    this.apiClient.get(Endpoint.customer + '/GetListToCreateOrder', data); // Customers
  getCustomerNoPaging = (data = null) => this.apiClient.get(Endpoint.customer + '/NoPaging', data);
  createCustomer = (data) => this.apiClient.post(Endpoint.customer, data);
  updateCustomer = (id, data) => this.apiClient.put(Endpoint.customer + `/${id}`, data);
  deleteCustomer = (id) => this.apiClient.delete(Endpoint.customer + `/${id}`);
  existsCodeCustomer = (data = null, id) =>
    this.apiClient.get(Endpoint.customer + `/ExistsCode/${id}`, data);
  customerSearch = (data = null, keyword) =>
    this.apiClient.get(Endpoint.customer + `/Search/${keyword}`, data);
  getCustomerListToAssignVendor = (data = null) =>
    this.apiClient.get(Endpoint.customer + `/GetListToAssignVendor`, data);

  GetCustomerToAssignPartner = (data = null) =>
    this.apiClient.get(Endpoint.customer + `/GetListToAssignPartner`, data);
  exportCustomer = (data = null) =>
    this.apiClient.getCustomBlod(Endpoint.customer + '?export=true', data);
  // Gara
  getGara = (data = null) => this.apiClient.get(Endpoint.gara, data);
  createGara = (data) => this.apiClient.post(Endpoint.gara, data);
  updateGara = (id, data) => this.apiClient.put(Endpoint.gara + `/${id}`, data);
  deleteGara = (id) => this.apiClient.delete(Endpoint.gara + `/${id}`);
  existsCodeGara = (data = null, id) =>
    this.apiClient.get(Endpoint.gara + `/ExistsCode/${id}`, data);
  garaSearch = (data = null, keyword) =>
    this.apiClient.get(Endpoint.gara + `/Search/${keyword}`, data);
  // MaterialType
  getMarterialTypes = (data = null) => this.apiClient.get(Endpoint.materialType, data);
  createMarterialType = (data) => this.apiClient.post(Endpoint.materialType, data);
  updateMarterialType = (id, data) => this.apiClient.put(Endpoint.materialType + `/${id}`, data);
  deleteMarterialType = (id) => this.apiClient.delete(Endpoint.materialType + `/${id}`);
  existsCodeMaterialType = (data = null, id) =>
    this.apiClient.get(Endpoint.materialType + `/ExistsCode/${id}`, data);
  materialTypeSearch = (data = null, keyword) =>
    this.apiClient.get(Endpoint.materialType + `/Search/${keyword}`, data);
  // Unit
  getUnit = (data = null) => this.apiClient.get(Endpoint.unit, data);
  createUnit = (data) => this.apiClient.post(Endpoint.unit, data);
  updateUnit = (id, data) => this.apiClient.put(Endpoint.unit + `/${id}`, data);
  deleteUnit = (id) => this.apiClient.delete(Endpoint.unit + `/${id}`);
  existsCodeUnit = (data = null, id) =>
    this.apiClient.get(Endpoint.unit + `/ExistsCode/${id}`, data);
  unitSearch = (data = null, keyword) =>
    this.apiClient.get(Endpoint.unit + `/Search/${keyword}`, data);
  // Car
  getCars = (data = null) => this.apiClient.get(Endpoint.car, data);
  getCarDetails = (id, data = null) => this.apiClient.get(Endpoint.car + `/GetDetails/${id}`, data);
  createCar = (data) => this.apiClient.post(Endpoint.car, data);
  updateCar = (id, data) => this.apiClient.put(Endpoint.car + `/${id}`, data);
  deleteCar = (id) => this.apiClient.delete(Endpoint.car + `/${id}`);
  existsCodeCar = (data = null, id) => this.apiClient.get(Endpoint.car + `/ExistsCode/${id}`, data);
  carSearch = (data = null, keyword) =>
    this.apiClient.get(Endpoint.car + `/Search/${keyword}`, data);
  getListByArrayIdOfCar = (data = null) =>
    this.apiClient.postCustom(Endpoint.car + `/GetListByArrayId`, data);
  updateCurrentLocationOfCar = (carId, locationId, data) =>
    this.apiClient.put(Endpoint.car + `/${carId}/Location/${locationId}`, data);
  // Supplier-Material
  getSupplierMaterials = (data = null) => this.apiClient.get(Endpoint.supplierMaterial, data);
  getSupplierMaterialId = (data = null, id) =>
    this.apiClient.get(Endpoint.supplierMaterial + `/GetDetails/${id}`, data);
  createSupplierMaterial = (data) => this.apiClient.post(Endpoint.supplierMaterial, data);
  updateSupplierMaterial = (id, data) =>
    this.apiClient.put(Endpoint.supplierMaterial + `/${id}`, data);
  deleteSupplierMaterial = (id) => this.apiClient.delete(Endpoint.supplierMaterial + `/${id}`);
  existsSupplierMaterial = (data = null, id) =>
    this.apiClient.get(Endpoint.supplierMaterial + `/ExistsCode/${id}`, data);
  supplierMaterialSearch = (data = null, keyword) =>
    this.apiClient.get(Endpoint.supplierMaterial + `/Search/${keyword}`, data);
  // Driver
  getDrivers = (data = null) => this.apiClient.get(Endpoint.driver, data);
  createDriver = (data) => this.apiClient.post(Endpoint.driver, data);
  updateDriver = (id, data) => this.apiClient.put(Endpoint.driver + `/${id}`, data);
  deleteDriver = (id) => this.apiClient.delete(Endpoint.driver + `/${id}`);
  existsCodeDriver = (data = null, id) =>
    this.apiClient.get(Endpoint.driver + `/ExistsCode/${id}`, data);
  getDriverId = (data = null, id) =>
    this.apiClient.get(Endpoint.driver + `/GetDetails/${id}`, data);
  getListToAssignCar = (data = null, dataSend) =>
    this.apiClient.get(Endpoint.driver + `/GetListToAssignCar/${dataSend}`, data);
  getDriverMainListNotAssignCar = (data = null) =>
    this.apiClient.get(Endpoint.driver + `/GetListToAssignCar/Tai_Xe`, data);
  getDriverSupportListNotAssignCar = (data = null) =>
    this.apiClient.get(Endpoint.driver + `/GetListToAssignCar/Phu_Xe`, data);
  getListToCreateAccountDriver = (data = null) =>
    this.apiClient.get(Endpoint.driver + `/GetListToCreateAccount`, data);
  driverSearch = (data = null, keyword) =>
    this.apiClient.get(Endpoint.driver + `/Search/${keyword}`, data);
  getListDriversReport = (data = null) =>
    this.apiClient.postCustom(Endpoint.driver + `/ListDrivers`, data);
  // LocationType
  getLocationTypes = (data = null) => this.apiClient.get(Endpoint.locationType, data);
  createLocationType = (data) => this.apiClient.post(Endpoint.locationType, data);
  updateLocationType = (id, data) => this.apiClient.put(Endpoint.locationType + `/${id}`, data);
  deleteLocationType = (id) => this.apiClient.delete(Endpoint.locationType + `/${id}`);
  existsCodeLocationType = (data = null, id) =>
    this.apiClient.get(Endpoint.locationType + `/ExistsCode/${id}`, data);
  // ShippingLine
  getShippingLines = (data = null) => this.apiClient.get(Endpoint.shippingLine, data);
  createShippingLine = (data) => this.apiClient.post(Endpoint.shippingLine, data);
  updateShippingLine = (id, data) => this.apiClient.put(Endpoint.shippingLine + `/${id}`, data);
  deleteShippingLine = (id) => this.apiClient.delete(Endpoint.shippingLine + `/${id}`);
  existsCodeShippingLine = (data = null, id) =>
    this.apiClient.get(Endpoint.shippingLine + `/ExistsCode/${id}`, data);
  shippingLineSearch = (data = null, keyword) =>
    this.apiClient.get(Endpoint.shippingLine + `/Search/${keyword}`, data);

  // Port
  getPorts = (data = null) => this.apiClient.get(Endpoint.port, data);
  createPort = (data) => this.apiClient.post(Endpoint.port, data);
  updatePort = (id, data) => this.apiClient.put(Endpoint.port + `/${id}`, data);
  deletePort = (id) => this.apiClient.delete(Endpoint.port + `/${id}`);
  existsCodePort = (data = null, id) =>
    this.apiClient.get(Endpoint.port + `/ExistsCode/${id}`, data);
  portSearch = (data = null, keyword) =>
    this.apiClient.get(Endpoint.port + `/Search/${keyword}`, data);
  // Area
  getAreas = (data = null) => this.apiClient.get(Endpoint.area, data);
  createArea = (data) => this.apiClient.post(Endpoint.area, data);
  updateArea = (id, data) => this.apiClient.put(Endpoint.area + `/${id}`, data);
  deleteArea = (id) => this.apiClient.delete(Endpoint.area + `/${id}`);
  existsCodeArea = (data = null, id) =>
    this.apiClient.get(Endpoint.area + `/ExistsCode/${id}`, data);
  areaSearch = (data = null, keyword) =>
    this.apiClient.get(Endpoint.area + `/Search/${keyword}`, data);
  // Location
  getLocations = (data = null) => this.apiClient.get(Endpoint.location, data);
  getLocationsNotUse = (data = null) => this.apiClient.get(Endpoint.location + '/NotUse', data);
  changeLocation = (id, data) => this.apiClient.put(Endpoint.location + `/${id}/Status`, data);
  getDetailsLocation = (id, data = null) =>
    this.apiClient.get(Endpoint.location`/GetDetails/${id}`, data);
  createLocation = (data) => this.apiClient.post(Endpoint.location, data);
  updateLocation = (id, data) => this.apiClient.put(Endpoint.location + `/${id}`, data);
  deleteLocation = (id) => this.apiClient.delete(Endpoint.location + `/${id}`);
  existsCodeLocation = (data = null, id) =>
    this.apiClient.get(Endpoint.location + `/ExistsCode/${id}`, data);
  locationSearch = (data = null, keyword) =>
    this.apiClient.get(Endpoint.location + `/Search/${keyword}`, data);
  locationSearchQuery = (data = null, query) =>
    this.apiClient.get(Endpoint.location + `/Get/${query}`, data);
  exportLocation = (data = null) =>
    this.apiClient.getCustomBlod(Endpoint.location + '?export=true', data);
  // Materials
  getMaterials = (data = null) => this.apiClient.get(Endpoint.material, data);
  createMaterial = (data) => this.apiClient.post(Endpoint.material, data);
  updateMaterial = (id, data) => this.apiClient.put(Endpoint.material + `/${id}`, data);
  deleteMaterial = (id) => this.apiClient.delete(Endpoint.material + `/${id}`);
  existsCodeMaterial = (data = null, id) =>
    this.apiClient.get(Endpoint.material + `/ExistsCode/${id}`, data);
  materialSearch = (data = null, keyword) =>
    this.apiClient.get(Endpoint.material + `/Search/${keyword}`, data);
  // Tire
  getTires = (data = null) => this.apiClient.get(Endpoint.tire, data);
  createTire = (data) => this.apiClient.post(Endpoint.tire, data);
  updateTire = (id, data) => this.apiClient.put(Endpoint.tire + `/${id}`, data);
  deleteTire = (id) => this.apiClient.delete(Endpoint.tire + `/${id}`);
  existsCodeTire = (data = null, id) =>
    this.apiClient.get(Endpoint.tire + `/ExistsCode/${id}`, data);
  tireSearch = (data = null, keyword) =>
    this.apiClient.get(Endpoint.tire + `/Search/${keyword}`, data);
  // TaskLists
  getTaskLists = (data = null) => this.apiClient.get(Endpoint.tasklist, data);
  createTaskList = (data) => this.apiClient.post(Endpoint.tasklist, data);
  updateTaskList = (id, data) => this.apiClient.put(Endpoint.tasklist + `/${id}`, data);
  deleteTaskList = (id) => this.apiClient.delete(Endpoint.tasklist + `/${id}`);
  getTaskListsTypeSupport = (data = null) =>
    this.apiClient.get(Endpoint.tasklist + '/GetByType/Support', data);
  // Romoocs
  getRomoocs = (data = null) => this.apiClient.get(Endpoint.romooc, data);

  getRomoocsWhiteboard = (data = null) => {
    const dataQuery = { Columns: { ...data?.Columns, isMain: true } };
    return this.apiClient.get(Endpoint.romooc, dataQuery);
  };
  createRomooc = (data) => this.apiClient.post(Endpoint.romooc, data);
  updateRomooc = (id, data) => this.apiClient.put(Endpoint.romooc + `/${id}`, data);
  deleteRomooc = (id) => this.apiClient.delete(Endpoint.romooc + `/${id}`);
  existsCodeRomooc = (data = null, id) =>
    this.apiClient.get(Endpoint.romooc + `/ExistsCode/${id}`, data);
  romoocSearch = (data = null, keyword) =>
    this.apiClient.get(Endpoint.romooc + `/Search/${keyword}`, data);
  getListByArrayIdOfRomooc = (data = null) =>
    this.apiClient.postCustom(Endpoint.romooc + `/GetListByArrayId`, data);
  updateCurrentLocationOfRomooc = (carId, locationId, data) =>
    this.apiClient.put(Endpoint.romooc + `/${carId}/Location/${locationId}`, data);

  // ContTypes
  getContTypes = (data = null) => this.apiClient.get(Endpoint.contType, data);
  createContType = (data) => this.apiClient.post(Endpoint.contType, data);
  updateContType = (id, data) => this.apiClient.put(Endpoint.contType + `/${id}`, data);
  deleteContType = (id) => this.apiClient.delete(Endpoint.contType + `/${id}`);
  existsCodeContType = (data = null, id) =>
    this.apiClient.get(Endpoint.contType + `/ExistsCode/${id}`, data);
  // CargoTypes
  getCargoTypes = (data = null) => this.apiClient.get(Endpoint.cargoType, data);
  createCargoType = (data) => this.apiClient.post(Endpoint.cargoType, data);
  updateCargoType = (id, data) => this.apiClient.put(Endpoint.cargoType + `/${id}`, data);
  deleteCargoType = (id) => this.apiClient.delete(Endpoint.cargoType + `/${id}`);
  // Oders
  getOrders = (data = null) => this.apiClient.get(Endpoint.order, data);
  exportOrders = (data = null) => this.apiClient.getCustomBlod(Endpoint.order, data);
  getOrderDetailsId = (id, data = null) =>
    this.apiClient.get(Endpoint.order + `/GetDetails/${id}`, data);
  createOrder = (data) => this.apiClient.post(Endpoint.order, data);
  updateBookingOrder = (id, data) =>
    this.apiClient.put(Endpoint.order + `/BookingInformation/${id}`, data);
  updateBillOrder = (id, data) =>
    this.apiClient.put(Endpoint.order + `/BillInformation/${id}`, data);
  updateTransitOrder = (id, data) =>
    this.apiClient.put(Endpoint.order + `/TransitInformation/${id}`, data);
  deleteOrder = (id, data = '""') => this.apiClient.deleteCustom(Endpoint.order + `/${id}`, data);
  //Distance
  getDistances = (data = null) => this.apiClient.get(Endpoint.distance, data);
  createDistance = (data) => this.apiClient.post(Endpoint.distance, data);
  updateDistance = (id, data) => this.apiClient.put(Endpoint.distance + `/${id}`, data);
  deleteDistance = (id) => this.apiClient.delete(Endpoint.distance + `/${id}`);
  getDistance = (id) => this.apiClient.get(Endpoint.distance + `/GetDetails/${id}`);
  getExistCodeDistance = (id) => this.apiClient.get(Endpoint.distance + `/ExistsCode/${id}`);
  checkIssetDistance = (data) =>
    this.apiClient.post(Endpoint.distance + '/CheckIssetDistance', data, null, null, false);
  checkIssetDistances = (data) =>
    this.apiClient.post(Endpoint.distance + '/CheckIssetDistances', data, null, null, false);
  createDistanceMultiple = (data) =>
    this.apiClient.post(Endpoint.distance + '/InsertMany', data, null, null, false);
  exportExcelDistance = (data = null) => this.apiClient.getCustomBlod(Endpoint.distance, data);
  //Order
  createOrderBooking = async (data = null) => {
    const formData = Utils.convertJsonToFormData(data);
    return this.apiClient.postFormData(Endpoint.order + '/Booking', formData);
  };
  createOrderBill = (data = null) => {
    console.log({ data });
    const formData = Utils.convertJsonToFormData(data);
    // window.formData = formData;
    return this.apiClient.postFormData(Endpoint.order + '/Bill', formData);
  };
  createOrderTransit = (data) => this.apiClient.post(Endpoint.order + '/Transit', data);
  // TaskJob
  getTaskJobs = (data = null) =>
    this.apiClient.get(`${Endpoint.taskJob}/GetListByOrderOrContOrAll`, data);
  getTaskJobsDetailsId = (id, wb = false, data = null) =>
    this.apiClient.get(`${Endpoint.taskJob}/GetListByOrderOrContOrAll/?id=${id}&wb=${wb}`, data);
  createTaskJobGenerate = (id) => this.apiClient.post(`${Endpoint.taskJob}/Generate/${id}`);
  getTaskJobDiary = (data = null) => this.apiClient.get(`${Endpoint.taskJob}/Diary`, data);
  // TaskJob Diary 2
  getTaskJobDiary2 = (data = null) => this.apiClient.get(`${Endpoint.taskJob}/Diary2`, data);
  getTaskJobDiaryDetail = (data = null) =>
    this.apiClient.get(`${Endpoint.taskJob}/DiaryDetail`, data);

  assignRomooc = (id, data) =>
    this.apiClient.putCustom(`${Endpoint.taskJob}/AssignRomooc/${id}`, data);
  changeRomooc = (id, data) =>
    this.apiClient.postCustom(`${Endpoint.taskJob}/ChangeRomooc/${id}`, data);
  editTaskJobs = (id, data) => this.apiClient.put(`${Endpoint.taskJob}/${id}`, data);
  getListsAssign = (data = null) => this.apiClient.get(`${Endpoint.taskJob}/GetListAssign`, data);
  mergeTaskJobs = (data) =>
    this.apiClient.post(Endpoint.taskJob + '/Merge', data, null, null, false);
  createTaskJobVehicle = (data) => this.apiClient.post(Endpoint.taskJob, data);
  getContsByStatus1 = (data) =>
    this.apiClient.post(Endpoint.cont + '/GetContsByStatus', data, null, null, false);
  getContsByStatus = (search = '', data = [-1, 3], transit = false) =>
    this.apiClient.post(
      Endpoint.cont + `/GetContsByStatus?query=${search}&transit=${transit}`,
      data,
      null,
      null,
      false
    );

  // White - Board
  getWhiteBoardOne = (data = null) => this.apiClient.get(Endpoint.cont + `/WhiteBoardOne`, data);
  getWhiteBoardTwo = (data = null) => this.apiClient.get(Endpoint.taskJob + `/WhiteBoardTwo`, data);
  getWhiteBoardThree = (data = null) =>
    this.apiClient.get(Endpoint.taskJob + `/WhiteBoardThree`, data);
  getWhiteBoardFour = (data = null) => this.apiClient.get(Endpoint.cont + `/WhiteBoardFour`, data);
  getWhiteBoardFive = (data = null) =>
    this.apiClient.get(Endpoint.taskJob + `/WhiteBoardFive`, data);
  getSingleConts = (data = null) => this.apiClient.get(Endpoint.singleCont, data);
  createSingleCont = (contId, data = null) =>
    this.apiClient.post(Endpoint.singleCont + `/${contId}`, data, null, null, false);
  createTaskJobGenerateByConts = (data) =>
    this.apiClient.post(Endpoint.taskJob + '/GenerateByConts', data, null, null, false);
  getListRelated = (data) =>
    this.apiClient.post(Endpoint.taskJob + '/GetListRelated', data, null, null, false);
  getListRelatedByCont = (data) =>
    this.apiClient.post(Endpoint.taskJob + '/GetListRelatedByCont', data, null, null, false);
  noticeBookCargoReady = (data) =>
    this.apiClient.post(Endpoint.taskJob + '/NoticeBookCargoReady', data);
  noticeBillEmptyCont = (data) =>
    this.apiClient.post(Endpoint.taskJob + '/NoticeBillEmptyCont', data);
  updateNote = (id, data) =>
    this.apiClient.put(Endpoint.taskJob + `/UpdateNote/${id}?note=${data || '.'}`, null);
  updateContNote = (id, data) =>
    this.apiClient.put(Endpoint.cont + `/UpdateNote/${id}?note=${data || '.'}`, null);
  cancelContsLiftOn = (data) =>
    this.apiClient.postCustom(Endpoint.cont + `/CancelContsHasPlan`, data);
  cancelContsLiftOff = (data) =>
    this.apiClient.postCustom(Endpoint.cont + `/CancelContsHasNotice`, data);
  returnEmptyCont = (id) => this.apiClient.post(`${Endpoint.taskJob}/ReturnEmptyCont/${id}`); // Tạo cv đi trả rỗng cho cont mồ côi
  updateContNumber = ({
    contId,
    contNumber,
    contFiles = null,
    files = null,
    sealNumber = null,
  }) => {
    const data = { contId, contNumber, sealNumber, contFiles };
    files && (data.files = files);
    const formData = Utils.convertJsonToFormData(data, 'files', 'UploadFiles');
    if (!data?.contFiles || data?.contFiles?.length === 0) {
      formData.append('contFiles', null);
    }
    if (!data?.contNumber) {
      formData.append('contNumber', null);
    }
    if (!data?.sealNumber) {
      formData.append('sealNumber', null);
    }
    return this.apiClient.postFormData(Endpoint.cont + `/UpdateContNumber`, formData);
  };
  getListPlanByCar = (data = null) =>
    this.apiClient.get(`${Endpoint.taskJob}/GetListPlanByCar`, data);
  // History List Cars
  getListForCar = (id, data = null) =>
    this.apiClient.get(`${Endpoint.taskJob}/GetListForCar/${id}`, data);
  getFilterJobByKind = (data = null, type, sort) =>
    this.apiClient.get(`${Endpoint.taskJob}/FilterJobByKind?type=${type}&sort=${sort}`, data);
  updateBeginLocation = (data, idJob, idLocation) =>
    this.apiClient.post(
      `${Endpoint.taskJob}/UpdateBeginLocation/${idJob}/${idLocation}`,
      data,
      null,
      null,
      false
    );
  updateEndLocation = (data, idJob, idLocation) =>
    this.apiClient.post(
      `${Endpoint.taskJob}/UpdateEndLocation/${idJob}/${idLocation}`,
      data,
      null,
      null,
      false
    );

  getListByArrayId = (data) => {
    let ids = [];
    for (let el of data) {
      ids = [...ids, ...el];
    }
    return this.apiClient.postCustom(`${Endpoint.taskJob}/GetListByArrayId`, ids);
  };
  returnEmptyContBack = (data) =>
    this.apiClient.postCustom(`${Endpoint.taskJob}/ReturnEmptyContBack`, data);
  //assigns
  createAssign = (id, data) =>
    this.apiClient.post(Endpoint.assigns + `/Create/${id}`, data, null, null, false);
  createAssignPlan = (data) =>
    this.apiClient.post(Endpoint.assigns + `/AssignPlan`, data, null, null, false);
  cancelAssign = (id) => this.apiClient.put(Endpoint.assigns + `/Cancel/${id}`);

  // Users Management
  getUsers = (data = null) => this.apiClient.get(Endpoint.user, data);
  createUser = (data) => this.apiClient.post(Endpoint.user, data);
  updateUser = (id, data) => this.apiClient.put(Endpoint.user + `/${id}`, data);
  resetPasswordUser = (id, data) => this.apiClient.put(Endpoint.user + `/${id}/Reset`, data);
  deleteUser = (id) => this.apiClient.delete(Endpoint.user + `/${id}`);
  existsCodeUser = (data = null, id) =>
    this.apiClient.get(Endpoint.user + `/ExistsCode/${id}`, data);
  changePassword = ({ code, oldPassword, password }) =>
    this.apiClient.post(Endpoint.user + `/ChangePassword`, { code, oldPassword, password });
  getUserDetails = (id, data = null) =>
    this.apiClient.get(Endpoint.user + `/GetDetails/${id}`, data);
  // Tracking User App
  getUsersAppTracking = (data = null) => this.apiClient.get(Endpoint.user + `/DeviceApp`, data);
  // Vendor
  getVendors = (data = null) => this.apiClient.get(Endpoint.vendor, data);
  getAllVendorById = (companyId, data = null) =>
    this.apiClient.get(Endpoint.vendor + `/${companyId}/Partner`, data);
  createVendor = (data) => this.apiClient.post(Endpoint.vendor, data);
  updateVendor = (id, data) => this.apiClient.put(Endpoint.vendor + `/${id}`, data);
  deleteVendor = (id) => this.apiClient.delete(Endpoint.vendor + `/${id}`);
  existsVendor = (data = null, id) =>
    this.apiClient.get(Endpoint.vendor + `/ExistsCode/${id}`, data);
  getVendorDetails = (id, data = null) =>
    this.apiClient.get(Endpoint.vendor + `/GetDetails/${id}`, data);
  getVendorCustomerDetails = (id, data = null) =>
    this.apiClient.get(Endpoint.vendor + `/GetCustomersDetails/${id}`, data);
  getPartnerCustomerDetails = (id, data = null) =>
    this.apiClient.get(Endpoint.company + `/GetCustomersDetails/${id}`, data);
  getPartnerCustomerDetailsAll = (id, data = null) =>
    this.apiClient.get(Endpoint.company + `/GetCustomersDetails/${id}/All`, data);
  changeCustomerVendor = (data) => this.apiClient.post(Endpoint.vendor + '/ChangeCustomer', data);
  changeCustomerPartner = (companyId, data) =>
    this.apiClient.post(Endpoint.company + `/ChangeCustomer/${companyId}`, data);
  changeCustomerPartnerLv2 = (companyId, partnerId, data) =>
    this.apiClient.post(
      Endpoint.company + `/ChangeCustomer/${companyId}/Partner/${partnerId}`,
      data
    );
  // Company
  getCompanies = (data = null) => this.apiClient.get(Endpoint.company, data);
  getCompanyAll = (data = null) => this.apiClient.get(Endpoint.company + '/all', data);

  // getPartnerByCompany = (data = null) => {
  //   const currentCompanyId = JSON.parse(
  //     localStorage.getItem('user_data_decode_token_tms')
  //   )?.CompanyId;

  //   return this.apiClient.get(Endpoint.company + `/${currentCompanyId}/Partner`, data);
  // };
  getPartners = (data = null) => this.apiClient.get(Endpoint.company + `/GetPartners`, data);
  getCompanyDetails = (id, data = null) => this.apiClient.get(Endpoint.company + `/${id}`, data);
  createCompany = (data) => this.apiClient.post(Endpoint.company, data);
  updateCompany = (id, data) => this.apiClient.put(Endpoint.company + `/${id}`, data);
  changeStatusCompany = (id, data) => this.apiClient.put(Endpoint.company + `/${id}/Status`, data);
  findCompanyWithCustomer = (id, data = null) =>
    this.apiClient.get(Endpoint.company + `/${id}/AssignCustomer`, data);
  // New
  getCompanyByTaxCode = (taxCode, data = null) =>
    this.apiClient.get(Endpoint.company + `/${taxCode}/TaxCode`, data);
  createConnectPartner = (currentCompany, partnerId, data) =>
    this.apiClient.post(Endpoint.company + `/${currentCompany}/Partner/${partnerId}`, data);
  getPartnerByCompany = (data = null) => {
    const currentCompanyId = JSON.parse(
      localStorage.getItem('user_data_decode_token_tms')
    )?.CompanyId;
    return this.apiClient.get(Endpoint.company + `/${currentCompanyId}/Partner`, data);
  };
  getPartnerByCompanyId = (companyId, data = null) => {
    return this.apiClient.get(Endpoint.company + `/${companyId}/Partner`, data);
  };
  deleteConnectPartner = (currentCompany, partnerId) =>
    this.apiClient.delete(Endpoint.company + `/${currentCompany}/Partner/${partnerId}`);
  getCompaniesPartner = (data = null) => this.apiClient.get(Endpoint.company + '/Partner', data);
  // Assign
  getByCarAndDateAssign = (idCar, date, data = null) =>
    this.apiClient.get(Endpoint.assign + `/GetByCarAndDateAssign/${idCar}/${date}`, data);
  acceptOrDecline = (data) => this.apiClient.post(Endpoint.assign + '/AcceptOrDecline', data);
  updateAssignDate = (id, data) => this.apiClient.put(Endpoint.assign + `/Date/${id}`, data);
  createAssignTransit = (carId, data) =>
    this.apiClient.post(Endpoint.assign + `/Create/${carId}/Transit`, data);
  deleteAssignTransit = (assignId) =>
    this.apiClient.delete(Endpoint.assign + `/${assignId}/Transit`);
  deleteAssign = (assignId) => this.apiClient.delete(Endpoint.assign + `/${assignId}`);
  // Order
  updateBooking = (id, data) => {
    let fileDetail = data?.orderFiles || [];
    const orderFilesCheck = fileDetail.length && fileDetail.filter((file) => !file?.originFileObj);
    const filesCheck = fileDetail.length && fileDetail.filter((file) => file?.originFileObj);

    data.orderFiles = orderFilesCheck?.length ? orderFilesCheck : null;
    data.files = filesCheck?.length ? filesCheck : null;

    const formData = Utils.convertJsonToFormData(data, 'files');
    return this.apiClient.putFormData(Endpoint.updateBooking + `/${id}`, formData);
  };
  updateBill = (id, data) => {
    let fileDetail = data?.orderFiles || [];
    const orderFilesCheck = fileDetail.length && fileDetail.filter((file) => !file?.originFileObj);
    const filesCheck = fileDetail.length && fileDetail.filter((file) => file?.originFileObj);

    data.orderFiles = orderFilesCheck?.length ? orderFilesCheck : null;
    data.files = filesCheck?.length ? filesCheck : null;

    const formData = Utils.convertJsonToFormData(data, 'files');
    return this.apiClient.putFormData(Endpoint.updateBill + `/${id}`, formData);
  };
  updateTransit = (id, data) => this.apiClient.put(Endpoint.updateTransit + `/${id}`, data);
  deleteCont = (id) => this.apiClient.delete(Endpoint.cont + `/${id}`);
  addContOrder = (id, data) => this.apiClient.postCustom(Endpoint.addContOrder + `/${id}`, data);

  updateBookingCont = (id, data) => {
    const formData = Utils.convertJsonToFormData(data, 'files');
    if (!data?.contFiles || data?.contFiles?.length === 0) {
      formData.append('contFiles', null);
    }
    if (!data?.contNumber) {
      formData.append('contNumber', null);
    }
    if (!data?.sealNumber) {
      formData.append('sealNumber', null);
    }
    if (!data?.locationId) {
      formData.append('locationId', null);
      formData.append('locationName', null);
    }
    return this.apiClient.putFormData(Endpoint.cont + `/BookingCont/${id}`, formData);
  };
  updateBillCont = (id, data) => {
    const formData = Utils.convertJsonToFormData(data, 'files');
    if (!data?.contFiles || data?.contFiles?.length === 0) {
      formData.append('contFiles', null);
    }
    if (!data?.contNumber) {
      formData.append('contNumber', null);
    }
    if (!data?.sealNumber) {
      formData.append('sealNumber', null);
    }

    if (!data?.locationId) {
      formData.append('locationId', null);
      formData.append('locationName', null);
    }

    return this.apiClient.putFormData(Endpoint.cont + `/BillCont/${id}`, formData);
  };

  changeBookBillContToNotComplete = (data = null) =>
    this.apiClient.postCustom(Endpoint.cont + '/BackAllCompleteCont', data);

  getRvsList = (data) => this.apiClient.get(`${Endpoint.order}/GetRVS`, data);
  getRvsById = (id) => this.apiClient.get(`${Endpoint.order}/GetRVSDetails/${id}`);
  // Complete All Booking Cont - Bill Cont
  completedAllBookingCont = (data = null) =>
    this.apiClient.postCustom(Endpoint.cont + `/CompleteAllBookingCont`, data);
  exportExcelBookingCont = (dateFrom = null, dateTo = null, data = null) =>
    this.apiClient.postCustomBlob(
      Endpoint.reportOilKmExport + `?export=true&dateFrom=${dateFrom}&dateTo=${dateTo}`,
      data
    );
  completedAllBillCont = (data = null) =>
    this.apiClient.postCustom(Endpoint.cont + `/CompleteAllBillCont`, data);

  //report Home
  reportRomooc = (data) => this.apiClient.get(Endpoint.reportRomooc, data);
  getListRoomocByStatus = (query = {}, status = false) =>
    this.apiClient.get(Endpoint.romooc + `/GetListByStatus/${status}`, query);
  reportOrder = (data = []) => this.apiClient.postCustom(Endpoint.reportOrderByStatus, data);
  reportVehicle = (data = []) => this.apiClient.postCustom(Endpoint.reportVehicle, data);
  reportFuelByVendor = (data = []) => this.apiClient.postCustom(Endpoint.reportFuelByVendor, data);
  reportOrderByCustomer = (data = []) =>
    this.apiClient.postCustom(Endpoint.reportOrderByCustomer, data);

  reportOrderByVendor = (data = []) =>
    this.apiClient.postCustom(Endpoint.reportOrderByVendor, data);
  removeRomooc = (data = []) => this.apiClient.putCustom(Endpoint.taskJob + '/RemoveRomoocs', data);
  getReportDrivingLicense = (data) => this.apiClient.get(Endpoint.reportDrivingLicense, data);
  // OilCoupons
  getOilCoupons = (data, date) => this.apiClient.get(Endpoint.oilCoupons + `/Get/${date}`, data);
  getDetailsOilCoupon = (id, data = null) =>
    this.apiClient.get(Endpoint.oilCoupons + `/GetDetails/${id}`, data);
  getMultipleOilTicketDetail = (data) =>
    this.apiClient.postCustom(Endpoint.oilCoupons + `/GetAllDetails`, data);
  printOil = (data) => this.apiClient.post(Endpoint.oilCoupons + `/PrintOil`, data);
  printMultipleOilTicket = (data) => this.apiClient.post(Endpoint.oilCoupons + `/PrintOils`, data);
  exportOilCouponList = (date, data = null) =>
    this.apiClient.postCustomBlob(`${Endpoint.oilCoupons}/ExportOilList/${date}`, data);
  getDataExportOilCouponList = (date, data = null) =>
    this.apiClient.post(`${Endpoint.oilCoupons}/ExportOilList/${date}`, data);
  // LogNotifications
  getLogNotifications = (data) => this.apiClient.get(Endpoint.logNotifications + '/Get', data);
  getCountNotificationNotRead = (data) =>
    this.apiClient.get(Endpoint.logNotifications + '/CountNotificationNotRead', data);
  readNotification = (id, data) =>
    this.apiClient.post(
      Endpoint.logNotifications + `/HaveReadNotification/${id}`,
      data,
      null,
      null,
      false
    );
  registerNotify = (register, data) =>
    this.apiClient.post(Endpoint.user + `/NotifyRegister/${register}`, data);
  // Report 7010 Theo khách hàng
  getRPDetailContByCustomer = (data) =>
    this.apiClient.postCustom(Endpoint.rPDetailContByCustomer, data);
  //  Report 7010-1 nhà xe ủy quyền
  getRPDetailContByCustomerCommission = (data) =>
    this.apiClient.postCustom(Endpoint.rPDetailContByCustomerCommission, data);
  //  Report 7010-2 sản lượng cont của unitrans
  getRPDetailContByCustomerUnitrans = (data) =>
    this.apiClient.postCustom(Endpoint.rPDetailContByCustomerUnitrans, data);

  getRPDetailContByShippingLine = (data) =>
    this.apiClient.postCustom(Endpoint.rPDetailContByShippingLine, data);
  getRPDetailOilKM = (data) => this.apiClient.postCustom(Endpoint.rPDetailOilKM, data);
  getRPDetailAllJob = (data, param) =>
    this.apiClient.postCustom(Endpoint.rPDetailAllJob, data, param);
  // CarInSpections
  getCarInspection = (idCar, data = null) =>
    this.apiClient.get(Endpoint.carInspection + `/${idCar}`, data);
  createCarInspection = (data) => this.apiClient.post(Endpoint.carInspection, data);
  createCarInspectionFormData = async (data = null) => {
    const formData = Utils.convertJsonToFormData(data, 'files', 'Files');
    return this.apiClient.postFormData(Endpoint.carInspection, formData);
  };
  // putFormData
  updateCarInspection = (id, data) => this.apiClient.put(Endpoint.carInspection + `/${id}`, data);
  updateCarInspectionFormData = (id, data) => {
    const formData = Utils.convertJsonToFormData(data, 'files', 'Files');
    return this.apiClient.putFormData(Endpoint.carInspection + `/${id}`, formData);
  };

  // CarInsurance
  getCarInsurance = (idCar, data = null) =>
    this.apiClient.get(Endpoint.carInsurance + `/${idCar}`, data);
  createCarInsurance = (data) => this.apiClient.post(Endpoint.carInsurance, data);
  createCarInsuranceFormData = async (data = null) => {
    const formData = Utils.convertJsonToFormData(data, 'files', 'Files');
    return this.apiClient.postFormData(Endpoint.carInsurance, formData);
  };
  updateCarInsurance = (id, data) => this.apiClient.put(Endpoint.carInsurance + `/${id}`, data);
  updateCarInsuranceFormData = (id, data) => {
    const formData = Utils.convertJsonToFormData(data, 'files', 'Files');
    return this.apiClient.putFormData(Endpoint.carInsurance + `/${id}`, formData);
  };
  // CarSign
  getCarSign = (idCar, data = null) => this.apiClient.get(Endpoint.carSign + `/${idCar}`, data);
  createCarSign = (data) => this.apiClient.post(Endpoint.carSign, data);
  createCarSignFormData = async (data = null) => {
    const formData = Utils.convertJsonToFormData(data, 'files', 'Files');
    return this.apiClient.postFormData(Endpoint.carSign, formData);
  };
  updateCarSign = (id, data) => this.apiClient.put(Endpoint.carSign + `/${id}`, data);
  updateCarSignFormData = (id, data) => {
    const formData = Utils.convertJsonToFormData(data, 'files', 'Files');
    return this.apiClient.putFormData(Endpoint.carSign + `/${id}`, formData);
  };
  // RomoocInspection
  getRomoocInspection = (idCar, data = null) =>
    this.apiClient.get(Endpoint.romoocInspection + `/${idCar}`, data);
  createRomoocInspection = (data) => this.apiClient.post(Endpoint.romoocInspection, data);
  createRomoocInspectionFormData = async (data = null) => {
    const formData = Utils.convertJsonToFormData(data, 'files', 'Files');
    return this.apiClient.postFormData(Endpoint.romoocInspection, formData);
  };
  updateRomoocInspection = (id, data) =>
    this.apiClient.put(Endpoint.romoocInspection + `/${id}`, data);
  updateRomoocInspectionFormData = (id, data) => {
    const formData = Utils.convertJsonToFormData(data, 'files', 'Files');
    return this.apiClient.putFormData(Endpoint.romoocInspection + `/${id}`, formData);
  };
  // Report register
  getRPRomoocInspection = (data = null) => this.apiClient.get(Endpoint.rPRomoocInspection, data);
  getRPVehicleInspection = (data) => this.apiClient.postCustom(Endpoint.rPVehicleInspection, data);
  // Report tour by date
  getReportTourByMoth = (data) => this.apiClient.postCustom(Endpoint.reportTourByMonth, data);
  getReportTourByDate = (data) => this.apiClient.postCustom(Endpoint.reportTourByDate, data);
  // Report detail tour
  getReportDetailTour = (data) => this.apiClient.postCustom(Endpoint.reportDetailTour, data);
  // Data Transfers
  getFromONP = (data = null) => this.apiClient.get(Endpoint.dataTransfers + '/GetFromONP', data);
  importONPToTMS = (data) =>
    this.apiClient.postCustom(Endpoint.dataTransfers + '/ImportONPToTMS', data);
  getFromWMS = (data = null) => this.apiClient.get(Endpoint.dataTransfers + '/GetFromWMS', data);
  importWMSToTMS = (data) =>
    this.apiClient.postCustom(Endpoint.dataTransfers + '/ImportWMSToTMS', data);
  deleteTransferBackJob = (id) =>
    this.apiClient.delete(Endpoint.dataTransfers + `/${id}/TransferBackJobs`);
  // Replacement Categories
  getReplacementCategories = (data = null) =>
    this.apiClient.get(Endpoint.replacementCategories, data);
  createReplacementCategory = (data) => this.apiClient.post(Endpoint.replacementCategories, data);
  updateReplacementCategory = (id, data) =>
    this.apiClient.put(Endpoint.replacementCategories + `/${id}`, data);
  deleteReplacementCategory = (id) =>
    this.apiClient.delete(Endpoint.replacementCategories + `/${id}`);
  //RPVehicleReplacement
  getRPVehicleReplacement = (data) =>
    this.apiClient.postCustom(Endpoint.rPVehicleReplacement, data);
  postCarReplacementList = (idCar, data) =>
    this.apiClient.postCustom(Endpoint.carReplacementList + `/${idCar}`, data);
  // Tranfer DataTransfers/Transfer
  transferConts = (data) => this.apiClient.post(Endpoint.dataTransfers + `/Transfer`, data);
  // ============================
  getListCont = (data) => this.apiClient.get(Endpoint.cont + `/HandleConts`, data);
  getListContCompleted = (data) => this.apiClient.get(Endpoint.cont + `/HandleCompleteConts`, data);
  exportExcelHandleCont = (data = null) =>
    this.apiClient.getCustomBlod(Endpoint.cont + '/HandleConts', data);
  exportExcelHandleCompletedCont = (data = null) =>
    this.apiClient.getCustomBlod(Endpoint.cont + '/HandleCompleteConts', data);
  // ===== By Partner ============
  getListContByPartnerId = (partnerId, data) =>
    this.apiClient.get(Endpoint.cont + `/HandleConts/${partnerId}`, data);
  getListContCompletedByPartnerId = (partnerId, data) =>
    this.apiClient.get(Endpoint.cont + `/HandleCompleteConts/${partnerId}`, data);
  exportExcelHandleContNumberByPartnerId = (partnerId, data = null, params) =>
    this.apiClient.postCustomBlob(
      Endpoint.cont + `/ExportHandleContNumber/${partnerId}`,
      data,
      params
    );

  completeBookingCont = (id, data = null) =>
    this.apiClient.put(Endpoint.cont + `/CompleteBookingCont/${id}`, data);
  completeBillCont = (id, data = null) =>
    this.apiClient.put(Endpoint.cont + `/CompleteBillCont/${id}`, data);
  //Change status task
  changeStatusUploadFile = ({
    jobId,
    updateValue,
    files = null,
    contNumber = null,
    sealNumber = null,
  }) => {
    const data = { jobId, updateValue };
    files && (data.files = files);
    contNumber && (data.contNumber = contNumber);
    sealNumber && (data.sealNumber = sealNumber);
    const formData = Utils.convertJsonToFormData(data, 'files', 'Files');
    return this.apiClient.postFormData(`${Endpoint.taskJob}/ChangeStatusUploadFile`, formData);
  };
  // depotCont
  getDepotConts = (query = null) => this.apiClient.get(Endpoint.depotCont, query);
  updateDepotCont = (id, depotLocation) =>
    this.apiClient.put(Endpoint.cont + `/UpdateDepot/${id}?depotLocation=${depotLocation}`);
  acceptOrDeclineAssign = (data) => this.apiClient.post(Endpoint.acceptOrDeclineAssign, data);

  // Export excel
  exportWhiteBoardThree = (data) =>
    this.apiClient.postCustomBlob(`${Endpoint.taskJob}/ExportWhiteBoardThree`, data);
  getDataToExportWhiteBoardThree = (data) =>
    this.apiClient.post(`${Endpoint.taskJob}/ExportWhiteBoardThree`, data);
  exportTaskJobByPartner = (partnerId, data = null) =>
    this.apiClient.getCustomBlod(Endpoint.taskJob + `/GetListByPartner/${partnerId}`, data);
  // report Problem
  reportProblem = (data) => this.apiClient.post(`${Endpoint.taskJob}/Trouble`, data);
  getTaskJobsListToChangeKindA = (id, data = null) =>
    this.apiClient.get(`${Endpoint.taskJob}/WhiteBoardTwoExcept/${id}`, data);
  getTaskJobsListToChangeKindB = (id, data = null) =>
    this.apiClient.get(`${Endpoint.taskJob}/WhiteBoardThreeExcept/${id}`, data);
  changeTaskJob = (data) => this.apiClient.post(`${Endpoint.taskJob}/Change`, data);
  replaceLocation = (data) => this.apiClient.post(`${Endpoint.taskJob}/ReplaceLocation`, data);
  //transferJobs
  transferJobs = (data) => this.apiClient.post(Endpoint.transferJobs, data);
  cancelTransferJob = (data) => this.apiClient.postCustom(Endpoint.cancelTransferJobs, data);
  getListTransferJobs = (query) => this.apiClient.get(Endpoint.getListTransfer, query);

  completeJobsFromPartner = (data) =>
    this.apiClient.postFormData(Endpoint.completeJobsFromPartner, data);

  checkOrderExistCode = (id) => this.apiClient.get(`${Endpoint.order}/ExistsCode`, { id });
  rollBackCont = (data) => this.apiClient.put(Endpoint.rollBackCont, data);
  searchContNumber = (data) => this.apiClient.get(Endpoint.searchContNumber, data);
  getContNote = (id) => this.apiClient.get(`${Endpoint.cont}/GetNotes/${id}`);
  updateAllNote = (id, data) => this.apiClient.put(`${Endpoint.cont}/UpdateAllNotes/${id}`, data);
  importContNumber = (file) =>
    this.apiClient.postFileConverted(`${Endpoint.cont}/ImportContNumber`, file);
  importBookingContNumber = (file) =>
    this.apiClient.postFileConverted(`${Endpoint.cont}/ImportBookingContNumber`, file);
  // import Data
  importLocation = (file) =>
    this.apiClient.postFileConverted(`${Endpoint.importData}/Location`, file);
  importLocationType = (file) =>
    this.apiClient.postFileConverted(`${Endpoint.importData}/LocationType`, file);
  importDistance = (file) =>
    this.apiClient.postFileConverted(`${Endpoint.importData}/Distance`, file);
  importRomooc = (file) => this.apiClient.postFileConverted(`${Endpoint.importData}/Romooc`, file);
  importCar = (file) => this.apiClient.postFileConverted(`${Endpoint.importData}/Car`, file);
  importCustomer = (file) =>
    this.apiClient.postFileConverted(`${Endpoint.importData}/Customer`, file);
  importBooking = (file) =>
    this.apiClient.postFileConverted(`${Endpoint.importData}/Booking`, file);
  importBill = (file) => this.apiClient.postFileConverted(`${Endpoint.importData}/Bill`, file);
  importTransit = (file) =>
    this.apiClient.postFileConverted(`${Endpoint.importData}/Transit`, file);
  importDriver = (file) => this.apiClient.postFileConverted(`${Endpoint.importData}/Driver`, file);
  //Authorizations Menu Apis
  getMenus = (query) => this.apiClient.get(Endpoint.menu, query);
  getMenu = (id) => this.apiClient.get(`${Endpoint.menu}/GetDetails/${id}`);
  createMenu = (data) => this.apiClient.post(Endpoint.menu, data);
  updateMenu = (id, data) => this.apiClient.put(`${Endpoint.menu}/${id}`, data);
  deleteMenu = (id) => this.apiClient.delete(`${Endpoint.menu}/${id}`);
  createMenuFunction = (id, data) =>
    this.apiClient.postCustom(`${Endpoint.menu}/SetFunctions/${id}`, data);
  updateMenuFunction = (id, data) =>
    this.apiClient.put(`${Endpoint.menu}/UpdateFunction/${id}`, data);
  deleteMenuFunction = (id) => this.apiClient.delete(`${Endpoint.menu}/DeleteFunction/${id}`);
  getPermissions = (query) => this.apiClient.get(`${Endpoint.group}/GetPermissions`, query);
  //Authorizations Group Apis
  getGroups = (query) => this.apiClient.get(Endpoint.group, query);
  createGroup = (data) => this.apiClient.post(Endpoint.group, data);
  getGroup = (id) => this.apiClient.get(`${Endpoint.group}/GetDetails/${id}`);
  updateGroup = (id, data) => this.apiClient.put(`${Endpoint.group}/${id}`, data);
  deleteGroup = (id) => this.apiClient.delete(`${Endpoint.group}/${id}`);
  setUserGroup = (data) => this.apiClient.post(`${Endpoint.group}/SetUser`, data);
  getGroupExistsCode = (id) => this.apiClient.get(`${Endpoint.group}/ExistsCode/${id}`);
  setFunctionsGroup = (data) => this.apiClient.post(`${Endpoint.group}/SetSystemFunction`, data);
  //Authorizations Menu Functions
  getFunctions = (query) => this.apiClient.get(Endpoint.function, query);
  createFunction = (data) => this.apiClient.post(Endpoint.function, data);
  syncDataFunctionsImport = (data) => this.apiClient.post(Endpoint.function + '/Import', data);

  getFunction = (id) => this.apiClient.get(`${Endpoint.function}/GetDetails/${id}`);
  updateFunction = (id, data) => this.apiClient.put(`${Endpoint.function}/${id}`, data);
  deleteFunction = (id) => this.apiClient.delete(`${Endpoint.function}/${id}`);
  // Goods Material Notes
  // getGoodsMaterialNotes = (data) =>
  //   this.apiClient.get(Endpoint.goodsMaterialNotes + '/GetGoodsMaterialNotes', data);
  // importGoodsMaterialNotesInWarehouse = (file) =>
  //   this.apiClient.postFileConverted(
  //     Endpoint.goodsMaterialNotes + '/ImportGoodsIssue?warehouse=true',
  //     file
  //   );
  // importGoodsMaterialNotesOutCompany = (file) =>
  //   this.apiClient.postFileConverted(
  //     Endpoint.goodsMaterialNotes + '/ImportGoodsIssue?warehouse=false',
  //     file
  //   );
  // Goods Material Notes Outbound
  getGoodsMaterialNotesOutbound = (data) =>
    this.apiClient.get(Endpoint.goodsMaterialNotes + '/Outbound', data);
  importGoodsMaterialNotesOutboundInWarehouse = (file) =>
    this.apiClient.postFileConverted(
      Endpoint.goodsMaterialNotes + '/ImportOutbound?warehouse=true',
      file
    );
  importGoodsMaterialNotesOutboundOutCompany = (file) =>
    this.apiClient.postFileConverted(
      Endpoint.goodsMaterialNotes + '/ImportOutbound?warehouse=false',
      file
    );
  deleteGoodsMaterialNotesTicket = (id) =>
    this.apiClient.delete(`${Endpoint.goodsMaterialNotes}/${id}`);
  // Goods Material Notes Inbound
  getGoodsMaterialNotesInbound = (data) =>
    this.apiClient.get(Endpoint.goodsMaterialNotes + '/Inbound', data);
  importGoodsMaterialNotesInbound = (file) =>
    this.apiClient.postFileConverted(Endpoint.goodsMaterialNotes + '/ImportInbound', file);
  // Get Details Goods Material Notes
  getDetailsGoodsMaterialNotes = (id, data = null) =>
    this.apiClient.get(Endpoint.goodsMaterialNotes + `/${id}`, data);
  // Goods Material Notes Warehouse
  getGoodsMaterialNotesWarehouse = (data) =>
    this.apiClient.get(Endpoint.goodsMaterialNotes + '/Warehouse', data);
  postGoodsMaterialNotesWarehouseClosing = (data = null) =>
    this.apiClient.post(Endpoint.goodsMaterialNotes + '/WarehouseClosing', data);
  // TaskJob Extras
  getTaskJobExtras = (data = null, date) =>
    this.apiClient.get(Endpoint.taskJobExtras + `/${date}`, data);
  createTaskJobExtras = (data) => this.apiClient.post(Endpoint.taskJobExtras, data);
  updateTaskJobExtras = (id, data) => this.apiClient.put(`${Endpoint.taskJobExtras}/${id}`, data);
  printOilOfTaskJobExtras = (data) =>
    this.apiClient.post(Endpoint.taskJobExtras + `/PrintOils`, data);
  deleteTaskJobExtras = (id) => this.apiClient.delete(`${Endpoint.taskJobExtras}/${id}`);
  getAllDetailOfTaskJobExtras = (data) =>
    this.apiClient.postCustom(Endpoint.taskJobExtras + `/GetAllDetails`, data);
  // Orders from ONP (data transfer ONP)
  getOrdersFromONP = (data = null) => this.apiClient.get(Endpoint.dataTransfers + '/ONP', data);
  importOrdersFromONP = (data) =>
    this.apiClient.postCustom(`${Endpoint.dataTransfers}/ImportONP`, data);
  // Orders from WMS (data transfer WMS)
  getOrdersFromWMS = (data = null) => this.apiClient.get(Endpoint.dataTransfers + '/WMS', data);
  importOrdersFromWMS = (data) =>
    this.apiClient.postCustom(`${Endpoint.dataTransfers}/ImportWMS`, data);
  // Orders RVS from ONP (datatransfer)
  getOrdersRVSFromONP = (data = null) =>
    this.apiClient.get(Endpoint.dataTransfers + '/ONPRVS', data);
  importOrdersFromONPRVS = (data) =>
    this.apiClient.postCustom(`${Endpoint.dataTransfers}/ImportONPRVS`, data);
  // Orders RVS from WMS (datatransfer)
  getOrdersRVSFromWMS = (data = null) =>
    this.apiClient.get(Endpoint.dataTransfers + '/WMSRVS', data);
  importOrdersFromWMSRVS = (data) =>
    this.apiClient.postCustom(`${Endpoint.dataTransfers}/ImportWMSRVS`, data);
  // Orders CANCEL from ONP (datatransfer)
  getOrdersCancelFromONP = (data = null) =>
    this.apiClient.get(Endpoint.dataTransfers + '/ONPCancel', data);
  importOrdersFromONPCancel = (data) =>
    this.apiClient.postCustom(`${Endpoint.dataTransfers}/ImportONPCancel`, data);
  // Orders CANCEL from WMS (datatransfer)
  getOrdersCancelFromWMS = (data = null) =>
    this.apiClient.get(Endpoint.dataTransfers + '/WMSCancel', data);
  importOrdersFromWMSCancel = (data) =>
    this.apiClient.postCustom(`${Endpoint.dataTransfers}/ImportWMSCancel`, data);

  // Orders Rvs by TMS status =1 RVS, Status = 2 Create
  getOrderByTmsNotRead = (status = 2, data = null) =>
    this.apiClient.get(Endpoint.cancelOrders + `/${status}`, data);
  acceptReadOrderRvsByTms = (data) => this.apiClient.putCustom(Endpoint.cancelOrders, data);
  // Refused Orders RVS from ONP (datatransfer)
  refusedRVS = (data = null) => this.apiClient.post(Endpoint.dataTransfers + '/RefusedRVS', data);
  // Oil Prices
  getOilPrices = (data = null) => this.apiClient.get(Endpoint.oilPrices, data);
  createOilPrice = (data) => this.apiClient.post(Endpoint.oilPrices, data);
  updateOilPrice = (id, data) => this.apiClient.put(Endpoint.oilPrices + `/${id}`, data);
  deleteOilPrice = (id) => this.apiClient.delete(Endpoint.oilPrices + `/${id}`);
  // Report Oil Summary
  getReportOilSummary = (data = null) => this.apiClient.post(Endpoint.reportOilSummary, data);
  exportReportOilSummary = (dateFrom, dateTo, data = null) =>
    this.apiClient.postCustomBlob(
      Endpoint.reportOilSummaryExport + `?dateFrom=${dateFrom}&dateTo=${dateTo}`,
      data
    );
  // Report Oil Distribution
  getReportOilFuelDistribution = (data = null) =>
    this.apiClient.post(Endpoint.reportRPoilFuelDistribution, data);
  getReportOilFuelDistribution1 = (data = null, check = false, params) =>
    this.apiClient.postCustom(
      Endpoint.reportRPoilFuelDistribution + `/${check.toString()}`,
      data,
      params
    );
  exportOilFuelDistribution = (dateFrom, dateTo, data = null) =>
    this.apiClient.postCustomBlob(
      Endpoint.reportRPoilFuelDistributionExport + `?dateFrom=${dateFrom}&dateTo=${dateTo}`,
      data
    );
  //getDataExportOilFuelDistribution
  getDataExportOilFuelDistribution = (DateFrom, DateTo, check = false, data = null) =>
    this.apiClient.postCustom(
      Endpoint.reports +
        `/RPoilFuelDistributionExport/${check}?DateFrom=${DateFrom}&DateTo=${DateTo}`,
      data
    );

  importActualDateOilFuelDistribution = (file) =>
    this.apiClient.postFileConverted(`${Endpoint.oilCoupons}/ImportActualDate`, file);
  updateActualDate = (id, data) => this.apiClient.put(Endpoint.oilCoupons + `/${id}`, data);
  deleteActualDate = (id, data) => this.apiClient.putCustom(Endpoint.oilCoupons + `/${id}`, data);
  // Report Oil Distribution 2
  getReportOilFuelDistribution2 = (data = null, params) =>
    this.apiClient.postCustom(Endpoint.reportRPoilFuelDistribution2, data, params);
  exportOilFuelDistribution2 = (dateFrom, dateTo, data = null) =>
    this.apiClient.postCustomBlob(
      Endpoint.reportRPoilFuelDistribution2Export + `?dateFrom=${dateFrom}&dateTo=${dateTo}`,
      data
    );
  getDataExportOilFuelDistribution2 = (dateFrom, dateTo, data = null) =>
    this.apiClient.post(
      Endpoint.reportRPoilFuelDistribution2Export + `?dateFrom=${dateFrom}&dateTo=${dateTo}`,
      data
    );
  // Report Oil Km
  getReportOilKm = (data = null) => this.apiClient.post(Endpoint.reportOilKm, data);
  exportReportOilKm = (dateFrom, dateTo, data = null) =>
    this.apiClient.postCustomBlob(
      Endpoint.reportOilKmExport + `?dateFrom=${dateFrom}&dateTo=${dateTo}`,
      data
    );
  // Vats
  getVats = (data = null) => this.apiClient.get(Endpoint.vats, data);
  getVatsPaging = (data = null) => this.apiClient.get(Endpoint.vats + '/Paging', data);
  createVat = (data) => this.apiClient.post(Endpoint.vats, data);
  updateVat = (id, data) => this.apiClient.put(Endpoint.vats + `/${id}`, data);
  deleteVat = (id) => this.apiClient.delete(Endpoint.vats + `/${id}`);
  getVatByDate = (date, data = null) => this.apiClient.get(Endpoint.vats + `/${date}`, data);
  // Expenses
  getExpenses = (data = null) => this.apiClient.get(Endpoint.expenses, data);
  createExpenses = (data) => this.apiClient.post(Endpoint.expenses, data);
  updateExpenses = (id, data) => this.apiClient.put(Endpoint.expenses + `/${id}`, data);
  deleteExpenses = (id) => this.apiClient.delete(Endpoint.expenses + `/${id}`);
  // Get List Task Job By Partner
  getTaskJobsByPartner = (partnerId, data = null) =>
    this.apiClient.get(Endpoint.taskJobsByPartner + `/${partnerId}`, data);
  // Get List Task Job By Partner
  getTaskJobsReceivedFromPartner = (partnerId, data = null) =>
    this.apiClient.get(Endpoint.taskJobsReceivedPartner + `/${partnerId}`, data);
  // Get List Task Job Transit By Partner
  getTaskJobsTransitReceivedFromPartner = (partnerId, data = null) =>
    this.apiClient.get(Endpoint.taskJobsReceivedPartner + `/${partnerId}/Transit`, data);
  // Tickets
  // Bill
  getBillCont = (data = null) => this.apiClient.get(Endpoint.ticket + '/BillCont', data);
  getBillByContId = (id, data = null) => this.apiClient.get(Endpoint.ticket + `/Bill/${id}`, data);
  createBillCont = (contId, data = null) =>
    this.apiClient.post(Endpoint.ticket + `/BillCont/${contId}`, data);
  updateTicketBillCont = (id, data) =>
    this.apiClient.put(Endpoint.ticket + `/BillCont/${id}`, data);
  getBillContList = (data = null) => this.apiClient.get(Endpoint.ticket + '/false/Bill', data);
  exportTicketBillCont = (dateFrom, dateTo, data = null) =>
    this.apiClient.getCustomBlod(
      Endpoint.ticket + `/true/Bill?DateFrom=${dateFrom}&DateTo=${dateTo}`,
      data
    );
  getDataExportTicketBillCont = (dateFrom, dateTo, data = null) =>
    this.apiClient.get(Endpoint.ticket + `/true/Bill?DateFrom=${dateFrom}&DateTo=${dateTo}`, data);
  // Booking
  getBookingCont = (data = null) => this.apiClient.get(Endpoint.ticket + '/BookingCont', data);
  getBookingByContId = (id, data = null) =>
    this.apiClient.get(Endpoint.ticket + `/Booking/${id}`, data);
  createBookingCont = (contId, data = null) =>
    this.apiClient.post(Endpoint.ticket + `/BookingCont/${contId}`, data);
  updateTicketBookingCont = (id, data) =>
    this.apiClient.put(Endpoint.ticket + `/BookingCont/${id}`, data);

  exportTicketBooking = (dateFrom, dateTo, data = null) =>
    this.apiClient.getCustomBlod(
      Endpoint.ticket + `/true/Booking?DateFrom=${dateFrom}&DateTo=${dateTo}`,
      data
    );
  getDataExportTicketBooking = (dateFrom, dateTo, data = null) =>
    this.apiClient.get(
      Endpoint.ticket + `/true/Booking?DateFrom=${dateFrom}&DateTo=${dateTo}`,
      data
    );
  getBookingList = (data = null) => this.apiClient.get(Endpoint.ticket + '/false/Booking', data);

  toggleStatusTicket = (id, data = null) =>
    this.apiClient.put(Endpoint.ticket + `/Status/${id}`, data);
  acceptMultipleTickets = (data = null) =>
    this.apiClient.putCustom(Endpoint.ticket + `/Statuses`, data);
  // Job
  getJobTicketByCarId = (carId, data = null) =>
    this.apiClient.get(Endpoint.ticket + `/Job/${carId}/Car`, data);
  getJobTicketByVendorId = (carId, data = null) =>
    this.apiClient.get(Endpoint.ticket + `/Job/${carId}/Vendor`, data);

  // Xe ngoài
  getJobTicketByVendorIdToActionByVendor = (vendorId, data = null) =>
    this.apiClient.get(Endpoint.ticket + `/Export/${vendorId}/Vendor?export=false`, data);

  getJobTicketByContId = (contId, data = null) =>
    this.apiClient.get(Endpoint.ticket + `/Job/${contId}`, data);
  createJobTicket = (contId, data = null) =>
    this.apiClient.post(Endpoint.ticket + `/Job/${contId}`, data);
  createJobForVendorTicket = (contId, data = null) =>
    this.apiClient.post(Endpoint.ticket + `/Vendor/${contId}`, data);
  createJobForVendorTicketWithAuthorizedCont = (contId, data = null) =>
    this.apiClient.post(Endpoint.ticket + `/CommissionCont/${contId}`, data);
  updateTicketJob = (id, data) => this.apiClient.put(Endpoint.ticket + `/Job/${id}`, data);
  exportTicketJob = (dateFrom, dateTo, data = null) =>
    this.apiClient.getCustomBlod(
      Endpoint.ticket + `/true/Job?DateFrom=${dateFrom}&DateTo=${dateTo}`,
      data
    );
  getDataExportTicketJob = (dateFrom, dateTo, data = null) =>
    this.apiClient.get(Endpoint.ticket + `/true/Job?DateFrom=${dateFrom}&DateTo=${dateTo}`, data);
  getTicketJobList = (data = null) => this.apiClient.get(Endpoint.ticket + '/false/Job', data);

  exportTicketJobAllCars = (data = null) =>
    this.apiClient.getCustomBlod(Endpoint.ticket + '/Job/true/AllCars', data);

  // 5041 UNILOG
  getTicketJobListForUniLog = (data = null) =>
    this.apiClient.get(Endpoint.ticket + '/false/Unilog', data);
  getDataExportTicketJobForUniLog = (dateFrom, dateTo, data = null) =>
    this.apiClient.get(
      Endpoint.ticket + `/true/Unilog?DateFrom=${dateFrom}&DateTo=${dateTo}`,
      data
    );

  // Update Ticket For Vendor
  getTicketJobVendorList = (exportExcel = false, data = null) =>
    this.apiClient.get(Endpoint.ticket + `/${exportExcel}/Vendor`, data);

  getDataExportTicketJobVendor = (companyId, dateFrom, dateTo, data = null) =>
    this.apiClient.getCustomBlod(
      Endpoint.ticket + `/Export/${companyId}/Vendor?DateFrom=${dateFrom}&DateTo=${dateTo}`,
      data
    );
  importExcelVendorTicket = (file) =>
    this.apiClient.postFileConverted(Endpoint.ticket + '/ImportTicketByVendor', file);

  // get Details Bill, Booking, Job tickets
  getDetailTicket = (ticketId, data = null) =>
    this.apiClient.get(Endpoint.ticket + `/${ticketId}`, data);
  updateMultipleDissolveDate = (data) =>
    this.apiClient.put(Endpoint.ticket + `/DissolveDate`, data);
  // Import excel for Bill, Booking, Job tickets
  importExcelForBookingBillTicket = (file) =>
    this.apiClient.postFileConverted(Endpoint.ticket + '/ImportTicket', file);
  importExcelForJobVendorTicket = (file) =>
    this.apiClient.postFileConverted(Endpoint.ticket + '/ImporTicketVendor', file);
  importExcelForJobTicket = (file) =>
    this.apiClient.postFileConverted(Endpoint.ticket + '/ImportTicketJob', file);

  importBookingBillTickets = (file) =>
    this.apiClient.postFileConverted(Endpoint.ticket + '/ImportAll', file);
  // Delete, Booking, Job tickets
  deleteTicket = (id) => this.apiClient.delete(Endpoint.ticket + `/${id}`);
  deleteFeeOfTicket = (ticketExpenseId) =>
    this.apiClient.delete(Endpoint.ticket + `/${ticketExpenseId}/Expense`);
  // SupplierBills
  getSupplierBills = (data = null) => this.apiClient.get(Endpoint.supplierBill, data);
  createSupplierBill = (data) => this.apiClient.post(Endpoint.supplierBill, data);
  updateSupplierBill = (id, data) => this.apiClient.put(Endpoint.supplierBill + `/${id}`, data);
  deleteSupplierBill = (id) => this.apiClient.delete(Endpoint.supplierBill + `/${id}`);

  // DissolveLocks
  getDissolveLocks = (data = null) => this.apiClient.get(Endpoint.dissolveLocks, data);
  createDissolveLock = (data) => this.apiClient.post(Endpoint.dissolveLocks, data);
  updateDissolveLock = (id, data) => this.apiClient.put(Endpoint.dissolveLocks + `/${id}`, data);
  deleteDissolveLocks = (id) => this.apiClient.delete(Endpoint.dissolveLocks + `/${id}`);
  // ManagementCosts
  getManagementCosts = (data = null) => this.apiClient.get(Endpoint.managementCosts, data);
  createManagementCost = (data) => this.apiClient.post(Endpoint.managementCosts, data);
  updateManagementCost = (id, data) =>
    this.apiClient.put(Endpoint.managementCosts + `/${id}`, data);
  deleteManagementCost = (id) => this.apiClient.delete(Endpoint.managementCosts + `/${id}`);
  // ManagementSalaries
  getManagementSalaries = (data = null) => this.apiClient.get(Endpoint.managementSalaries, data);
  createManagementSalary = (data) => this.apiClient.post(Endpoint.managementSalaries, data);
  updateManagementSalary = (id, data) =>
    this.apiClient.put(Endpoint.managementSalaries + `/${id}`, data);
  deleteManagementSalary = (id) => this.apiClient.delete(Endpoint.managementSalaries + `/${id}`);
  // Contracts
  getCurrentContracts = (data = null) => this.apiClient.get(Endpoint.contracts + '/Current', data);
  getContractNumberByCustomerId = (customerId, data = null) =>
    this.apiClient.get(Endpoint.contracts + `/${customerId}/ContractNumber`, data);
  getDistanceByFindLocation = (data = null, params) =>
    this.apiClient.postCustom(Endpoint.distance + `/FindLocation`, data, params);
  createContract = (data = null) => this.apiClient.post(Endpoint.contracts, data);
  getContractDetails = (contractId, data = null) =>
    this.apiClient.get(Endpoint.contracts + `/${contractId}`, data);
  updateContractService = (id, data) => this.apiClient.put(Endpoint.contracts + `/${id}`, data);
  deleteContract = (id) => this.apiClient.delete(Endpoint.contracts + `/${id}`);
  acceptContractService = (id, data) =>
    this.apiClient.put(Endpoint.contracts + `/${id}/Confirm`, data);
  reOpenContractService = (id, data) =>
    this.apiClient.put(Endpoint.contracts + `/${id}/Reopen`, data);
  updateContract = (id, data) =>
    this.apiClient.put(Endpoint.contracts + `/${id}/TransportFee`, data);
  // Contract for vendor
  getCurrentContractsPartner = (data = null) =>
    this.apiClient.get(Endpoint.contracts + '/CurrentPartner', data);
  getContractNumberByPartnerId = (partnerId, data = null) =>
    this.apiClient.get(Endpoint.contracts + `/${partnerId}/ContractNumberPartner`, data);
  createContractForVendor = (partnerId, contractCode, data = null) => {
    if (contractCode) {
      return this.apiClient.post(
        Endpoint.contracts + `/${partnerId}/ContractPartner?code=${contractCode}`,
        data
      );
    } else {
      return this.apiClient.post(Endpoint.contracts + `/${partnerId}/ContractPartner`, data);
    }
  };
  updateTransportFeePartner = (id, data) =>
    this.apiClient.put(Endpoint.contracts + `/${id}/TransportFeePartner`, data);
  getContractDetailByCode = (contractCode, data = null) =>
    this.apiClient.get(Endpoint.contracts + `/${contractCode}/ContractPartner`, data);
  deleteContractForVendor = (contractCode) =>
    this.apiClient.delete(Endpoint.contracts + `/${contractCode}/ContractPartner`);
  deleteOnlyContractForVendor = (contractCode, idContract) =>
    this.apiClient.delete(Endpoint.contracts + `/${contractCode}/ContractPartner?id=${idContract}`);
  changeDateOfInfoContract = (contractCode, data) =>
    this.apiClient.put(Endpoint.contracts + `/${contractCode}/ContractPartnerDate`, data);
  updateContractForVendor = (id, data) =>
    this.apiClient.put(Endpoint.contracts + `/${id}/ContractPartner`, data);
  acceptContractServiceForVendor = (contractCode, data) =>
    this.apiClient.put(Endpoint.contracts + `/${contractCode}/ConfirmPartner`, data);
  reOpenContractServiceForVendor = (contractCode, data) =>
    this.apiClient.put(Endpoint.contracts + `/${contractCode}/ReopenPartner`, data);
  // Delete Transport - Transit - Romoooc Fee
  deleteTransportFee = (id, isPartner = false) => {
    if (isPartner) {
      return this.apiClient.delete(Endpoint.contracts + `/${id}/Transport?isPartner=true`);
    } else {
      return this.apiClient.delete(Endpoint.contracts + `/${id}/Transport`);
    }
  };
  deleteTransitFee = (id, isPartner = false) => {
    if (isPartner) {
      return this.apiClient.delete(Endpoint.contracts + `/${id}/Transit?isPartner=true`);
    } else {
      return this.apiClient.delete(Endpoint.contracts + `/${id}/Transit`);
    }
  };
  deleteRomoocFee = (id, isPartner = false) => {
    if (isPartner) {
      return this.apiClient.delete(Endpoint.contracts + `/${id}/Romooc?isPartner=true`);
    } else {
      return this.apiClient.delete(Endpoint.contracts + `/${id}/Romooc?isPartner=false`);
    }
  };
  // Get history contract
  getHistoryEditContract = (contractId, data = null) =>
    this.apiClient.get(Endpoint.contracts + `/${contractId}/History`, data);
  getHistoryEditContractForVendor = (contractCode, data = null) =>
    this.apiClient.get(Endpoint.contracts + `/${contractCode}/HistoryPartner`, data);
  // Export Datas
  // Car
  exportCar = (data = null) => this.apiClient.getCustomBlod(Endpoint.exportDatas + '/Car', data);
  getDataToExportExcelForCar = (data = null) =>
    this.apiClient.get(Endpoint.exportDatas + '/Car', data);
  // Romooc
  exportRomooc = (data = null) =>
    this.apiClient.getCustomBlod(Endpoint.exportDatas + '/Romooc', data);
  getDataToExportExcelForRomooc = (data = null) =>
    this.apiClient.get(Endpoint.exportDatas + '/Romooc', data);

  exportDriver = (data = null) =>
    this.apiClient.getCustomBlod(Endpoint.exportDatas + '/Driver', data);
  exportUnit = (data = null) => this.apiClient.getCustomBlod(Endpoint.exportDatas + '/Unit', data);
  exportMaterial = (data = null) =>
    this.apiClient.getCustomBlod(Endpoint.exportDatas + '/Material', data);
  exportMaterialType = (data = null) =>
    this.apiClient.getCustomBlod(Endpoint.exportDatas + '/MaterialType', data);
  // Accountants
  getAccountantsByCustomerExpense = (customerId, data = null) =>
    this.apiClient.get(Endpoint.accountants + `/CustomerExpense/${customerId}`, data);
  getAccountantsCustomerExpenseBills = (customerId, data = null) =>
    this.apiClient.get(Endpoint.accountants + `/CustomerExpenseBills/${customerId}`, data);
  // For UI
  getAccountantsCustomerExpenseBillsForUI = (customerId, data = null) =>
    this.apiClient.get(Endpoint.accountants + `/VendorCustomerExpenseBills/${customerId}`, data);
  // For Vendor
  getAccountantsCustomerExpenseBillsForVendor = (customerId, data = null) =>
    this.apiClient.get(Endpoint.accountants + `/VendorCustomerExpenseBills/${customerId}`, data);

  getAccountantsGoodExpenseCar = (data = null) =>
    this.apiClient.get(Endpoint.accountants + `/GoodsExpenseCar`, data);
  // CarReplacements
  getCarReplacements = (data = null) => this.apiClient.get(Endpoint.carReplacements, data);
  createCarReplacement = (data) =>
    this.apiClient.post(Endpoint.carReplacements + '/CarReplacementItem', data);
  deleteCarReplacement = (id) => this.apiClient.delete(Endpoint.carReplacements + `/${id}`);

  // Report Accountants Booking Cont Unpaid
  getReportAccountantsBookingContUnpaid = (data = null) =>
    this.apiClient.get(Endpoint.accountants + `/BookingcontUnpaid/false`, data);
  exportReportAccountantsBookingContUnpaid = (data = null) =>
    this.apiClient.getCustomBlod(Endpoint.accountants + `/BookingcontUnpaid/true`, data);

  // Report Accountants Bills
  getReportAccountantsBills = (customerId, data = null) => {
    if (customerId === 'all') {
      return this.apiClient.get(Endpoint.accountants + `/Bills/false`, data);
    } else {
      return this.apiClient.get(
        Endpoint.accountants + `/Bills/false?customerId=${customerId}`,
        data
      );
    }
  };
  exportReportAccountantsBills = (dateFrom, dateTo, data = null) =>
    this.apiClient.getCustomBlod(
      Endpoint.accountants + `/Bills/true?DateFrom=${dateFrom}&DateTo=${dateTo}`,
      data
    );
  getDataToExportReportAccountantsBills = (data = null) =>
    this.apiClient.get(Endpoint.accountants + `/Bills/true`, data);
  // Report Accountants Transit Expense
  getReportAccountantsTransitExpense = (customerId, data = null) =>
    this.apiClient.get(Endpoint.accountants + `/TransitExpense/${customerId}`, data);
  getReportAccountantsTransitExpenseAllCustomer = (data = null) =>
    this.apiClient.get(Endpoint.accountants + `/TransitExpense`, data);
  // Goods Expense
  getAccountantsGoodExpense = (data = null) =>
    this.apiClient.get(Endpoint.accountants + `/GoodsExpense`, data);
  // Goods Expense Driver
  getAccountantsGoodExpenseDriver = (data = null) =>
    this.apiClient.get(Endpoint.accountants + `/GoodsExpenseDriver`, data);
  // Accountants partner expense
  getAccountantsByPartnerExpense = (partnerId, data = null) =>
    this.apiClient.get(Endpoint.accountants + `/PartnerExpense/${partnerId}`, data);
  // Goods Material Notes Outbound History
  getGoodsMaterialNotesOutboundHistory = (data) =>
    this.apiClient.get(Endpoint.goodsMaterialNotes + '/OutboundHistory', data);
  // Count All Orders From ONP, WMS
  getCountAllOrdersFromOnpWms = (data) =>
    this.apiClient.get(Endpoint.notifications + '/CountAll', data);
  // get Cont Difference Depot
  getContDifferenceDepot = (data = null) =>
    this.apiClient.get(Endpoint.cont + `/DifferenceDepot`, data);
  // DrivingLicenses
  getDrivingLicenses = (idCar, data = null) =>
    this.apiClient.get(Endpoint.drivingLicenses + `/${idCar}`, data);
  createDrivingLicenses = async (data = null) => {
    const formData = Utils.convertJsonToFormData(data, 'files', 'Files');
    return this.apiClient.postFormData(Endpoint.drivingLicenses, formData);
  };
  updateDrivingLicenses = (id, data) => {
    const formData = Utils.convertJsonToFormData(data, 'files', 'Files');
    return this.apiClient.putFormData(Endpoint.drivingLicenses + `/${id}`, formData);
  };
  // TransitConts
  getTransitConts = (data = null) => this.apiClient.get(Endpoint.transitConts, data);
  createTransitCont = (data) => this.apiClient.post(Endpoint.transitConts, data);
  updateTransitCont = (id, data) => this.apiClient.put(Endpoint.transitConts + `/${id}`, data);
  deleteTransitCont = (id) => this.apiClient.delete(Endpoint.transitConts + `/${id}`);
  exportExcelTransitConts = (data = null) =>
    this.apiClient.getCustomBlod(Endpoint.transitConts, data);
  // LocationConfigs
  getLocationConfigs = (data = null) => this.apiClient.get(Endpoint.locationConfigs, data);
  createLocationConfig = (data) => this.apiClient.post(Endpoint.locationConfigs, data);
  updateLocationConfig = (id, data) =>
    this.apiClient.put(Endpoint.locationConfigs + `/${id}`, data);
  deleteLocationConfig = (id) => this.apiClient.delete(Endpoint.locationConfigs + `/${id}`);
  //
  getCountCodeTransit = (transitDate, data) =>
    this.apiClient.get(`${Endpoint.order}/CountCodeTransit/${transitDate}`, data);

  // getReportOilFuelDistribution1 = (data = null, check = false, params) =>
  //   this.apiClient.postCustom(
  //     Endpoint.reportRPoilFuelDistribution + `/${check.toString()}`,
  //     data,
  //     params
  //   );
  exportExcelHandleContNumber = (data = null, params) =>
    this.apiClient.postCustomBlob(Endpoint.cont + '/ExportHandleContNumber', data, params);

  // Export Cont Search Number
  exportExcelContSearchNumber = (data = null) =>
    this.apiClient.getCustomBlod(Endpoint.cont + '/SearchContNumber', data);
  // Assign Transit Partner
  getListAssignTransitPartner = (data = null) =>
    this.apiClient.get(Endpoint.taskJob + `/GetListByPartner/Transit`, data);
  createAssignTransitPartner = (partnerId, data) =>
    this.apiClient.postCustom(Endpoint.assign + `/Create/${partnerId}/TransitVendor`, data);
  deleteAssignTransitPartner = (id) =>
    this.apiClient.delete(Endpoint.assign + `/${id}/TransitVendor`);

  // Change YCGC Date in order (edit detail cont)
  editYCGCDateInOrder = (id, dateChange, data = null) =>
    this.apiClient.put(Endpoint.cont + `/${id}/Date/${dateChange}`, data);
  // Report Accountants Booking Cont Unpaid
  getReportTransitCont = (data = null) =>
    this.apiClient.get(Endpoint.reports + `/ReportTransitCont`, data);
  // Take Photos
  getTakePhotos = (data = null) => this.apiClient.get(Endpoint.takePhotos, data);
  createTakePhoto = (data) => this.apiClient.post(Endpoint.takePhotos, data);
  deleteTakePhoto = (id) => this.apiClient.delete(Endpoint.takePhotos + `/${id}`);
  // Swap CONT
  swapCont = (contIdOld, contIdNew, data = null) =>
    this.apiClient.post(Endpoint.cont + `/SwapCont/${contIdOld}/${contIdNew}`, data);

  // LiftingFees
  getLiftingFees = (data = null) => this.apiClient.get(Endpoint.liftingFees, data);
  createLiftingFee = (data) => this.apiClient.post(Endpoint.liftingFees, data);
  updateLiftingFee = (data) => this.apiClient.post(Endpoint.liftingFees + '?insert=false', data);
  deleteLiftingFeeByLocation = (locationId) =>
    this.apiClient.delete(Endpoint.liftingFees + `/${locationId}/Location`);
  getLiftingFeeHistory = (locationId, data = null) =>
    this.apiClient.get(Endpoint.liftingFees + `/${locationId}/History`, data);
  exportExcelLiftingFeeHistory = (data = null) =>
    this.apiClient.getCustomBlod(Endpoint.liftingFees + '/Export', data);
  // get BookingContTracking
  getBookingContTracking = (data = null) =>
    this.apiClient.get(Endpoint.cont + `/BookingCont`, data);
  exportBookingContTracking = (data = null) =>
    this.apiClient.getCustomBlod(Endpoint.cont + '/BookingCont?export=true', data);
  // get BillContTracking
  getBillContTracking = (data = null) => this.apiClient.get(Endpoint.cont + `/BillCont`, data);
  exportBillContTracking = (data = null) =>
    this.apiClient.getCustomBlod(Endpoint.cont + '/BillCont?export=true', data);
  // OilCoupons - OilInput
  getOilInputs = (data = null) => this.apiClient.get(Endpoint.oilInput, data);
  createOilInput = (data) => this.apiClient.post(Endpoint.oilInput, data);
  updateOilInput = (id, data) => this.apiClient.put(Endpoint.oilInput + `/${id}`, data);
  deleteOilInput = (id) => this.apiClient.delete(Endpoint.oilInput + `/${id}`);
  exportOilInput = (data = null) => this.apiClient.getCustomBlod(Endpoint.oilInput, data);
  // OilCoupons - OilTotal
  getOilTotals = (data = null) => this.apiClient.get(Endpoint.oilTotal, data);
  getOilTotalByDate = (dateCheck, data = null) =>
    this.apiClient.get(Endpoint.oilTotal + `/${dateCheck}`, data);
  getOilPriceByDate = (dateCheck, data = null) =>
    this.apiClient.get(Endpoint.oilPrices + `/${dateCheck}`, data);
  // OilCoupons - OilCheck
  getOilChecks = (data = null) => this.apiClient.get(Endpoint.oilCheck, data);
  createOilCheck = (data) => this.apiClient.post(Endpoint.oilCheck, data);
  updateOilCheck = (id, data) => this.apiClient.put(Endpoint.oilCheck + `/${id}`, data);
  deleteOilCheck = (id) => this.apiClient.delete(Endpoint.oilCheck + `/${id}`);
  exportOilCheck = (data = null) => this.apiClient.getCustomBlod(Endpoint.oilCheck, data);
}
const restfullServices = new RestfullServices();
export default restfullServices;
