import React, { useEffect, useState, useCallback } from 'react';
const ExcelJS = require('exceljs');
import moment from 'moment';
import { debounce } from 'lodash';
import { Space, Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import ApiServices from '~services';
import { RangeDateFilter, Selection } from '~components';
import { TableDetail } from './table-detail';
import logo from '~assets/images/logo.png';
import {
  LIST_COLUMNS_TITLE_FIRST_SUMMARY_SHEET,
  LIST_COLUMNS_TITLE_SECOND_SUMMARY_SHEET,
  MERGE_TWO_ROWS_TITLE_SUMMARY_SHEET,
  MERGE_TWO_COLUMNS_TITLE_SUMMARY_SHEET,
  BORDER_CELL_TITLE_SUMMARY_SHEET,
  COLUMNS_BORDER_LIST_SUMMARY_SHEET,
  LIST_COLUMN_SUM_SUMMARY_SHEET,
} from './sheet-summary-constant';

import {
  LIST_COLUMNS_TITLE_FIRST_DETAIL_SHEET,
  LIST_COLUMNS_TITLE_SECOND_DETAIL_SHEET,
  MERGE_TWO_ROWS_TITLE_DETAIL_SHEET,
  MERGE_TWO_COLUMNS_TITLE_DETAIL_SHEET,
  BORDER_CELL_TITLE_DETAIL_SHEET,
  COLUMNS_BORDER_LIST_DETAIL_SHEET,
  LIST_COLUMN_SUM_DETAIL_SHEET,
} from './sheet-detail-constants';
import Utils from '~commons/utils';

const toDataURL = (url) => {
  const promise = new Promise((resolve) => {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.readAsDataURL(xhr.response);
      reader.onloadend = function () {
        resolve({ base64Url: reader.result });
      };
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  });
  return promise;
};

const DEFAULT_QUERIES = {
  DateFrom: moment().startOf('month').format(),
  DateTo: moment().endOf('date').format(),
};

const ReportAccountantsCustomerExpenseBillForUI = () => {
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState(null);
  const [bills, setBills] = useState(null);
  const [detail, setDetail] = useState([]);
  const [filter, setFilter] = useState(DEFAULT_QUERIES);
  const [customers, setCustomers] = useState([]);
  const [customerId, setCustomerId] = useState('');
  const [customerSelected, setCustomerSelected] = useState({});

  const getCustomer = async (query = '') => {
    if (loading) return;
    setLoading(true);
    try {
      let { data } = await ApiServices.getCustomerForUILog({ query });
      if (data?.data) {
        setCustomers(data?.data);
      }
    } catch (error) {
      console.log('Get customer list failed:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCustomer();
  }, []);

  const getData = async (query = DEFAULT_QUERIES, cusId) => {
    setLoading(true);
    const newQuery = { ...query };
    // const customerId1 = '60cc11ea2c250000fb00133a';
    return ApiServices.getAccountantsCustomerExpenseBillsForUI(cusId, newQuery)
      .then((res) => {
        if (res?.data?.data?.company) {
          setCompany(res?.data?.data?.company);
        } else {
          setCompany(null);
        }
        if (res?.data?.data?.detail) {
          const dataMap = res?.data?.data?.detail?.map((item) => {
            let listExpense = {
              thanhLyTK: 0,
              dO: 0,
              billNumberDO: '',
              luuBai: 0,
              billNumberLuuBai: '',
              chuyenContRottau: 0,
              billNumberChuyenContRotTau: '',
              veSinh: 0,
              billNumberVeSinh: '',
              luuCont: 0,
              billNumberLuuCont: '',
              chuyenTai: 0,
              billNumberChuyenTai: '',
              phuThu: 0,
              billNumberPhuThu: '',
              muaSeal: 0,
              billNumberMuaSeal: '',
              nang: 0,
              billNumberNang: '',
              ha: 0,
              billNumberHa: '',
              totals: 0,
            };
            item?.objExpense?.map((el) => {
              switch (el?.code) {
                case 'thanh-ly-tk':
                  listExpense.thanhLyTK = el?.total;
                  listExpense.totals = listExpense.totals + el.total;
                  return;
                case 'do':
                  listExpense.dO = el?.total;
                  listExpense.billNumberDO = el?.billNumber;
                  listExpense.totals = listExpense.totals + el.total;
                  return;
                case 'luu-bai':
                  listExpense.luuBai = el?.total;
                  listExpense.billNumberLuuBai = el?.billNumber;
                  listExpense.totals = listExpense.totals + el.total;
                  return;
                case 'chuyen-cont-rot-tau':
                  listExpense.chuyenContRottau = el?.total;
                  listExpense.billNumberChuyenContRotTau = el?.billNumber;
                  listExpense.totals = listExpense.totals + el.total;
                  return;
                case 've-sinh':
                  listExpense.veSinh = el?.total;
                  listExpense.billNumberVeSinh = el?.billNumber;
                  listExpense.totals = listExpense.totals + el.total;
                  return;
                case 'luu-cont':
                  listExpense.luuCont = el?.total;
                  listExpense.billNumberLuuCont = el?.billNumber;
                  listExpense.totals = listExpense.totals + el.total;
                  return;
                case 'chuyen-tai':
                  listExpense.chuyenTai = el?.total;
                  listExpense.billNumberChuyenTai = el?.billNumber;
                  listExpense.totals = listExpense.totals + el.total;
                  return;
                case 'phu-thu':
                  listExpense.phuThu = el?.total;
                  listExpense.billNumberPhuThu = el?.billNumber;
                  listExpense.totals = listExpense.totals + el.total;
                  return;
                case 'mua-seal':
                  listExpense.muaSeal = el?.total;
                  listExpense.billNumberMuaSeal = el?.billNumber;
                  listExpense.totals = listExpense.totals + el.total;
                  return;
                case 'nang':
                  listExpense.nang = el?.total;
                  listExpense.billNumberNang = el?.billNumber;
                  listExpense.totals = listExpense.totals + el.total;
                  return;
                case 'ha':
                  listExpense.ha = el?.total;
                  listExpense.billNumberHa = el?.billNumber;
                  listExpense.totals = listExpense.totals + el.total;
                  return;

                default:
                  break;
              }
            });
            return { ...item, ...listExpense };
          });
          setDetail(dataMap || []);
        } else {
          setDetail([]);
        }
        if (res?.data?.data?.bills) {
          const dataBill = res?.data?.data?.bills?.filter(
            (item) => !(item?.orderType === 'Bill' && item?.code === 'nang' && item?.total < 0)
          );
          setBills(dataBill || []);
        } else {
          setBills(null);
        }
      })
      .catch((err) => {
        setCompany(null);
        setBills(null);
        setDetail([]);
        console.error('get Accountants By CustomerExpense Error: ', err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const timeoutGetData = useCallback(
    debounce((newFilter, newCustomerId) => {
      getData(newFilter, newCustomerId);
    }, 500),
    []
  );
  useEffect(() => {
    if (customerId) {
      timeoutGetData(filter, customerId);
    }
  }, [filter, customerId]);

  const exportExcelFile = async () => {
    if (loading) return;
    setLoading(true);
    const workbook = new ExcelJS.Workbook();
    // Ten sheet
    const sheetSummary = workbook.addWorksheet('Bảng Phí', {
      properties: { tabColor: { argb: 'FF00FF00' } },
      views: [{ showGridLines: false }],
    });
    // chieu cao - chieu rong cot
    sheetSummary.properties.defaultRowHeight = 21;
    sheetSummary.properties.defaultColWidth = 15;

    // Add Image
    // const pathLogo = company?.logo?.split('~')[1];
    // const urlLogo = `${process.env.REACT_APP_API_URL}${pathLogo}`;
    // const resultTest = await toDataURL(urlLogo);
    // const splittedTest = urlLogo.split('.');
    // const extNameTest = splittedTest[splittedTest.length - 1];
    const resultTest = await toDataURL(logo);
    const extNameTest = 'png';
    // --------- Summary Sheet --------------------
    const imageId2Summary = workbook.addImage({
      base64: resultTest.base64Url,
      extension: extNameTest,
    });

    sheetSummary.addImage(imageId2Summary, {
      tl: { col: 1, row: 0 },
      ext: { width: 80, height: 80 },
    });
    const dateStartToEnd = `Từ ${moment(filter?.DateFrom).format('DD/MM/YYYY')} Đến ${moment(
      filter?.DateTo
    ).format('DD/MM/YYYY')}`;
    const companyName = company?.name || '';
    const address = company?.address || '';
    const phone = company?.phone || '';
    const excelTitle = `BẢNG BÁO CÁO CHI TIẾT CHI PHÍ KHÁCH HÀNG ${customerSelected?.name}`;

    sheetSummary.addRow(['', '', '', companyName]); // blank Row
    sheetSummary.addRow(['', '', '', address]); // blank Row
    sheetSummary.addRow(['', '', '', phone]); // blank Row
    sheetSummary.addRow(['']); // blank Row
    sheetSummary.addRow(['']); // blank Row
    sheetSummary.addRow(['']); // blank Row
    // sheetSummary.addRow(['', '', '', '', '', excelTitle]); // blank Row
    sheetSummary.mergeCells('B7:AR7');
    sheetSummary.getCell('B7').value = excelTitle;
    sheetSummary.getRow(7).alignment = { vertical: 'middle', horizontal: 'center' };
    // sheetSummary.addRow(['', '', '', '', '', '', '', dateStartToEnd]); // blank Row
    sheetSummary.mergeCells('B8:AR8');
    sheetSummary.getCell('B8').value = dateStartToEnd;
    sheetSummary.getRow(8).alignment = { vertical: 'middle', horizontal: 'center' };
    sheetSummary.addRow(['']); // blank Row

    // add Title
    sheetSummary.addRow(LIST_COLUMNS_TITLE_FIRST_SUMMARY_SHEET);
    sheetSummary.addRow(LIST_COLUMNS_TITLE_SECOND_SUMMARY_SHEET);

    MERGE_TWO_ROWS_TITLE_SUMMARY_SHEET.forEach((item) => {
      sheetSummary.mergeCells(`${item}10:${item}11`);
      sheetSummary.getCell(`${item}10`).border = {
        top: { style: 'thin', color: { argb: '006699' } },
        left: { style: 'thin', color: { argb: '006699' } },
        right: { style: 'thin', color: { argb: '006699' } },
        bottom: { style: 'thin', color: { argb: '006699' } },
      };
      sheetSummary.getCell(`${item}10`).fill = {
        type: 'gradient',
        gradient: 'angle',
        stops: [
          { position: 0, color: { argb: '96B6C5' } },
          { position: 1, color: { argb: '96B6C5' } },
        ],
      };
    });

    MERGE_TWO_COLUMNS_TITLE_SUMMARY_SHEET.forEach((item) => {
      sheetSummary.mergeCells(`${item.start}10:${item.end}10`);
      sheetSummary.getCell(`${item.start}10`).border = {
        top: { style: 'thin', color: { argb: '006699' } },
        left: { style: 'thin', color: { argb: '006699' } },
        right: { style: 'thin', color: { argb: '006699' } },
        bottom: { style: 'thin', color: { argb: '006699' } },
      };
      sheetSummary.getCell(`${item.start}10`).fill = {
        type: 'gradient',
        gradient: 'angle',
        stops: [
          { position: 0, color: { argb: '96B6C5' } },
          { position: 1, color: { argb: '96B6C5' } },
        ],
      };
    });
    BORDER_CELL_TITLE_SUMMARY_SHEET.forEach((item) => {
      sheetSummary.getCell(`${item}11`).border = {
        top: { style: 'thin', color: { argb: '006699' } },
        left: { style: 'thin', color: { argb: '006699' } },
        right: { style: 'thin', color: { argb: '006699' } },
        bottom: { style: 'thin', color: { argb: '006699' } },
      };
      sheetSummary.getCell(`${item}11`).fill = {
        type: 'gradient',
        gradient: 'angle',
        stops: [
          { position: 0, color: { argb: '96B6C5' } },
          { position: 1, color: { argb: '96B6C5' } },
        ],
      };
    });

    // ================ Summary Body ====================
    await detail?.map((item, indexRow) => {
      const dateStart = item?.actualContainerBeginDate;
      const dateEnd = item?.actualContainerEndDate;
      const free = item?.romoocFeeDateContract ? item.romoocFeeDateContract : 0;

      const countSunday = Utils.countCheckDay(dateStart, dateEnd);
      const dateTru =
        Utils.getDaysDiff(
          moment(dateStart).format('YYYY-MM-DD'),
          moment(dateEnd).format('YYYY-MM-DD')
        ) - countSunday;

      const dateTruFinal = dateTru > free ? dateTru - free : 0;
      // * Phí neo Romooc = (Số ngày neo - số ngày neo đc miễn phí trong HĐ ) * Phí neo Romooc
      // => ( RomoocFeeActualContainerDate - RomoocFeeDateContract ) * RomoocFee
      // ( Tức là Số ngày neo > số ngày neo đc miễn phí trong HĐ, thì mới tính! Còn lại = 0 hết )

      const calcRomoocFee = dateTruFinal * item?.romoocFee;

      sheetSummary.addRow([
        '',
        indexRow + 1, // STT
        Utils.displayOrderTypeOrder(item?.orderType), // Loại hình
        item?.subnoNumber, // Số Book / Bill
        item?.createdOn && new Date(moment(item?.createdOn).format('YYYY-MM-DD')), // Ngày nhận
        item?.contNumber, // Số cont
        item?.contType === `20"` ? 1 : 0,
        item?.contType === '40C' ? 1 : 0,
        item?.contType === '40T' ? 1 : 0,
        item?.contType === `45"` ? 1 : 0,
        item?.deliveryAcctualDate &&
          new Date(moment(item?.deliveryAcctualDate).format('YYYY-MM-DD')), // Ngày giao cont
        item?.actualContainerEndDate &&
          new Date(moment(item?.actualContainerEndDate).format('YYYY-MM-DD')), // Ngày hạ cont
        item?.layCont || '', // Nơi lấy cont
        item?.tmpCont || '', // Nơi đóng/rút hàng
        item?.haCont || '', // Nơi hạ cont
        item?.xeHa || '', // So xe = Book: Số xe Hạ = Bill: Số xe Nâng
        item?.romooc || '', // Romooc
        dateTruFinal || 0, // So ngay neo
        // item?.romoocFeeDateContract || 0, // số ngay neo miễn phí
        // item?.romoocFee || 0, // Phi neo
        calcRomoocFee, // Phí neo
        item?.transportFee || 0, // Phi Van Chuyen
        item?.vat || 0, // VAT
        item?.thanhLyTK,
        item?.dO,
        item?.billNumberDO,
        item?.luuBai,
        item?.billNumberLuuBai,
        item?.chuyenContRottau,
        item?.billNumberChuyenContRotTau,
        item?.veSinh,
        item?.billNumberVeSinh,
        item?.luuCont,
        item?.billNumberLuuCont,
        item?.chuyenTai,
        item?.billNumberChuyenTai,
        item?.phuThu,
        item?.billNumberPhuThu,
        item?.muaSeal,
        item?.billNumberMuaSeal,
        item?.nang,
        item?.billNumberNang,
        item?.ha,
        item?.billNumberHa,
        item?.note || '', // ghi chu
        0, // calcRomoocFee + item?.transportFee + item?.vat + item?.totals,
      ]);

      COLUMNS_BORDER_LIST_SUMMARY_SHEET.forEach((item) => {
        sheetSummary.getCell(`${item}${indexRow + 12}`).border = {
          top: { style: 'thin', color: { argb: '006699' } },
          left: { style: 'thin', color: { argb: '006699' } },
          right: { style: 'thin', color: { argb: '006699' } },
          bottom: { style: 'thin', color: { argb: '006699' } },
        };
        // if (indexRow % 2 === 0) {
        //   sheetSummary.getCell(`${item}${indexRow + 12}`).fill = {
        //     type: 'gradient',
        //     gradient: 'angle',
        //     stops: [
        //       { position: 0, color: { argb: 'D8D8D8' } },
        //       { position: 1, color: { argb: 'D8D8D8' } },
        //     ],
        //   };
        // }
      });
    });
    // SUm
    const dataLength = detail?.length;
    const startPosition = 12;
    const endPosition = dataLength + 11;
    const showResult = dataLength + 12;
    // Sum Row
    for (let i = startPosition; i < dataLength + startPosition; i++) {
      sheetSummary.getCell(`AR:${i}`).value = {
        formula: `S${i} + T${i} + U${i} + V${i} + W${i} + Y${i} + AA${i}  + AC${i} + AE${i} + AG${i} + AI${i} + AK${i} + AM${i} + AO${i}`,
      };
    }
    // sheetSummary.getCell(`AR:10`).note = {
    //   texts: [
    //     {
    //       font: {
    //         size: 10,
    //         color: { theme: 1 },
    //         name: 'Arial',
    //         family: 2,
    //         scheme: 'minor',
    //         bold: true,
    //       },
    //       text: 'Duong Nguyen: ',
    //     },
    //     {
    //       font: { size: 8, color: { theme: 1 }, name: 'Arial', family: 2 },
    //       text: 'Tổng = Phí Neo + Phí Vận Chuyển + VAT + Thanh Lý TK + D/O + Lưu Bãi + Chuyển Cont Rớt Tàu + Vệ Sinh + Lưu Cont + Chuyển Tải + Phụ Thu + Mua Seal + Nâng + Hạ ',
    //     },
    //   ],
    // };
    if (dataLength > 0) {
      LIST_COLUMN_SUM_SUMMARY_SHEET.forEach((column) => {
        sheetSummary.getCell(`${column + showResult}`).value = {
          formula: `=SUM(${column + startPosition}:${column + endPosition})`,
        };
      });

      COLUMNS_BORDER_LIST_SUMMARY_SHEET.forEach((item) => {
        sheetSummary.getCell(`${item + showResult}`).border = {
          top: { style: 'thin', color: { argb: '006699' } },
          left: { style: 'thin', color: { argb: '006699' } },
          right: { style: 'thin', color: { argb: '006699' } },
          bottom: { style: 'thin', color: { argb: '006699' } },
        };
        sheetSummary.getCell(`${item + showResult}`).fill = {
          type: 'gradient',
          gradient: 'angle',
          stops: [
            { position: 0, color: { argb: '96B6C5' } },
            { position: 1, color: { argb: '96B6C5' } },
          ],
        };
      });
      sheetSummary.getRow(showResult).font = {
        name: 'Arial',
        family: 4,
        size: 10,
        bold: true,
      };
    }
    // sign
    sheetSummary.getCell(`B${showResult + 3}`).value = `Khách hàng:`;
    sheetSummary.getCell(`N${showResult + 3}`).value = 'Người Lập:';
    sheetSummary.getRow(showResult + 3).font = {
      name: 'Arial',
      family: 4,
      size: 10,
      bold: true,
    };
    // ================= Style ==========================
    // E=12, C=7, K=12, L=12, Q=9, R=6, S=11, T=12, U=11, V=10, Từ cột W -> AL=6, AN=9, AP=9
    // S, T, U, V
    sheetSummary.getColumn(1).width = 3.71;
    sheetSummary.getColumn(3).width = 7.71;
    sheetSummary.getColumn(5).width = 12.71;
    sheetSummary.getColumn(11).width = 12.71;
    sheetSummary.getColumn(12).width = 12.71;
    sheetSummary.getColumn(17).width = 9.71;
    sheetSummary.getColumn(18).width = 6.71;
    sheetSummary.getColumn(40).width = 9.71;
    sheetSummary.getColumn(42).width = 9.71;

    const listColumnsWith5 = [2, 7, 8, 9, 10];
    listColumnsWith5.forEach((column) => {
      sheetSummary.getColumn(column).width = 5;
    });

    const fontBoldRows = [
      { row: 1, size: 13 },
      { row: 7, size: 13 },
      { row: 8, size: 13 },
      { row: 10, size: 9 },
      { row: 11, size: 9 },
    ];

    fontBoldRows.forEach((item) => {
      sheetSummary.getRow(item.row).font = {
        name: 'Arial',
        family: 4,
        size: item.size,
        bold: true,
      };
    });

    const alignCenterRows = [10, 11];
    alignCenterRows.forEach((row) => {
      sheetSummary.getRow(row).alignment = { vertical: 'middle', horizontal: 'center' };
    });

    for (let i = 19; i <= 23; i++) {
      // sheetSummary.getColumn(i).numFmt = '#,##0';
      sheetSummary.getColumn(i).numFmt = '_(* #,##0_);_(* (#,##0);_(* "-"??_)';
    }
    sheetSummary.getColumn(19).width = 11.71;
    sheetSummary.getColumn(20).width = 12.71;
    sheetSummary.getColumn(21).width = 11.71;
    sheetSummary.getColumn(22).width = 10.71;

    const listFormatNumber = [25, 27, 29, 31, 33, 35, 37, 39, 41, 44];
    listFormatNumber.forEach((column) => {
      // sheetSummary.getColumn(column).numFmt = '#,##0';
      sheetSummary.getColumn(column).numFmt = '_(* #,##0_);_(* (#,##0);_(* "-"??_)';
    });
    sheetSummary.getColumn(39).width = 15;
    sheetSummary.getColumn(41).width = 15;
    sheetSummary.getColumn(44).width = 15;

    for (let i = 23; i <= 38; i++) {
      sheetSummary.getColumn(i).width = 6.71;
    }
    for (let i = 7; i <= 3000; i++) {
      sheetSummary.getRow(i).height = 21;
    }
    sheetSummary.getRow(4).height = 5;
    sheetSummary.getRow(5).height = 5;
    sheetSummary.getRow(6).height = 10;

    // ================= Style ==========================

    //            BANG KE HOA DON SHEET
    // Ten sheet
    const sheetDetail = workbook.addWorksheet('Bảng Kê Hóa Đơn', {
      properties: { tabColor: { argb: 'FF00FF00' } },
      views: [{ showGridLines: false }],
      pageSetup: { paperSize: 9, orientation: 'landscape' },
    });
    // chieu cao - chieu rong cot
    sheetDetail.properties.defaultRowHeight = 21;
    sheetDetail.properties.defaultColWidth = 15;
    // Add Image
    const imageId2Summary2 = workbook.addImage({
      base64: resultTest.base64Url,
      extension: extNameTest,
    });

    sheetDetail.addImage(imageId2Summary2, {
      tl: { col: 1, row: 0 },
      ext: { width: 80, height: 80 },
    });

    const sheetDetailTitle = 'BẢNG KÊ HÓA ĐƠN';
    const customerInfo = `To: ${customerSelected?.name}`;
    sheetDetail.addRow(['', '', '', companyName]); // blank Row
    sheetDetail.addRow(['', '', '', address]); // blank Row
    sheetDetail.addRow(['', '', '', phone]); // blank Row
    sheetDetail.addRow(['']); // blank Row
    sheetDetail.addRow(['']); // blank Row
    sheetDetail.addRow(['']); // blank Row
    // sheetDetail.addRow(['', '', '', '', '', sheetDetailTitle]); // blank Row
    sheetDetail.mergeCells('B7:K7');
    sheetDetail.getCell('B7').value = sheetDetailTitle;
    sheetDetail.getRow(7).alignment = { vertical: 'middle', horizontal: 'center' };
    // sheetDetail.addRow(['', '', '', '', '', dateStartToEnd]); // blank Row
    sheetDetail.mergeCells('B8:K8');
    sheetDetail.getCell('B8').value = dateStartToEnd;
    sheetDetail.getRow(8).alignment = { vertical: 'middle', horizontal: 'center' };
    // sheetDetail.addRow(['', customerInfo]); // blank Row
    sheetDetail.mergeCells('B9:K9');
    sheetDetail.getCell('B9').value = customerInfo;
    sheetDetail.addRow(['']); // blank Row

    // add Title
    sheetDetail.addRow(LIST_COLUMNS_TITLE_FIRST_DETAIL_SHEET);
    sheetDetail.addRow(LIST_COLUMNS_TITLE_SECOND_DETAIL_SHEET);

    MERGE_TWO_ROWS_TITLE_DETAIL_SHEET.forEach((item) => {
      sheetDetail.mergeCells(`${item}11:${item}12`);
      sheetDetail.getCell(`${item}11`).border = {
        top: { style: 'thin', color: { argb: '006699' } },
        left: { style: 'thin', color: { argb: '006699' } },
        right: { style: 'thin', color: { argb: '006699' } },
        bottom: { style: 'thin', color: { argb: '006699' } },
      };
      sheetDetail.getCell(`${item}11`).fill = {
        type: 'gradient',
        gradient: 'angle',
        stops: [
          { position: 0, color: { argb: '96B6C5' } },
          { position: 1, color: { argb: '96B6C5' } },
        ],
      };
    });

    MERGE_TWO_COLUMNS_TITLE_DETAIL_SHEET.forEach((item) => {
      sheetDetail.mergeCells(`${item.start}11:${item.end}11`);
      sheetDetail.getCell(`${item.start}11`).border = {
        top: { style: 'thin', color: { argb: '006699' } },
        left: { style: 'thin', color: { argb: '006699' } },
        right: { style: 'thin', color: { argb: '006699' } },
        bottom: { style: 'thin', color: { argb: '006699' } },
      };
      sheetDetail.getCell(`${item.start}11`).fill = {
        type: 'gradient',
        gradient: 'angle',
        stops: [
          { position: 0, color: { argb: '96B6C5' } },
          { position: 1, color: { argb: '96B6C5' } },
        ],
      };
    });

    BORDER_CELL_TITLE_DETAIL_SHEET.forEach((item) => {
      sheetDetail.getCell(`${item}12`).border = {
        top: { style: 'thin', color: { argb: '006699' } },
        left: { style: 'thin', color: { argb: '006699' } },
        right: { style: 'thin', color: { argb: '006699' } },
        bottom: { style: 'thin', color: { argb: '006699' } },
      };
      sheetDetail.getCell(`${item}12`).fill = {
        type: 'gradient',
        gradient: 'angle',
        stops: [
          { position: 0, color: { argb: '96B6C5' } },
          { position: 1, color: { argb: '96B6C5' } },
        ],
      };
    });
    // ================ Summary Body ====================
    await bills?.map((item, indexRow) => {
      sheetDetail.addRow([
        '',
        indexRow + 1, // STT
        Utils.displayOrderTypeOrder(item?.orderType), // Loại Hình
        item?.contNumber, // So Cont
        item?.billNumber, // So HD
        item?.billDate ? new Date(moment(item?.billDate).format('YYYY-MM-DD')) : '', // Ngay HD
        item?.supplierName, // Nha Cung Cap
        item?.expenseName, // Noi dung phi
        item?.price, // Chua thue
        item?.priceVAT, // Tien thue
        item?.total, // Cong
      ]);

      COLUMNS_BORDER_LIST_DETAIL_SHEET.forEach((item) => {
        sheetDetail.getCell(`${item}${indexRow + 13}`).border = {
          top: { style: 'thin', color: { argb: '006699' } },
          left: { style: 'thin', color: { argb: '006699' } },
          right: { style: 'thin', color: { argb: '006699' } },
          bottom: { style: 'thin', color: { argb: '006699' } },
        };
      });
    });
    // SUm
    const dataLengthDetailSheet = bills?.length;
    const startPositionDetailSheet = 13;
    const endPositionDetailSheet = dataLengthDetailSheet + 12;
    const showResultDetailSheet = dataLengthDetailSheet + 13;

    for (
      let i = startPositionDetailSheet;
      i <= dataLengthDetailSheet + startPositionDetailSheet;
      i++
    ) {
      sheetDetail.getRow(i).alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true,
      };
    }

    if (dataLengthDetailSheet > 0) {
      LIST_COLUMN_SUM_DETAIL_SHEET.forEach((column) => {
        sheetDetail.getCell(`${column + showResultDetailSheet}`).value = {
          formula: `=SUM(${column + startPositionDetailSheet}:${column + endPositionDetailSheet})`,
        };
      });

      COLUMNS_BORDER_LIST_DETAIL_SHEET.forEach((item) => {
        sheetDetail.getCell(`${item + showResultDetailSheet}`).border = {
          top: { style: 'thin', color: { argb: '006699' } },
          left: { style: 'thin', color: { argb: '006699' } },
          right: { style: 'thin', color: { argb: '006699' } },
          bottom: { style: 'thin', color: { argb: '006699' } },
        };
        sheetDetail.getCell(`${item + showResultDetailSheet}`).fill = {
          type: 'gradient',
          gradient: 'angle',
          stops: [
            { position: 0, color: { argb: '96B6C5' } },
            { position: 1, color: { argb: '96B6C5' } },
          ],
        };
      });
      sheetDetail.getRow(showResultDetailSheet).font = {
        name: 'Arial',
        family: 4,
        size: 10,
        bold: true,
      };
    }

    // sign
    sheetDetail.mergeCells(`I${showResultDetailSheet + 2}:K${showResultDetailSheet + 2}`);
    sheetDetail.getCell(`I${showResultDetailSheet + 2}`).value = `Ngày...Tháng...Năm...`;
    sheetDetail.getCell(`J${showResultDetailSheet + 3}`).value = 'Ký nhận';

    // ================= Style ==========================
    sheetDetail.getColumn(1).width = 3;
    sheetDetail.getColumn(2).width = 4;
    sheetDetail.getColumn(3).width = 10;
    sheetDetail.getColumn(4).width = 20;
    sheetDetail.getColumn(5).width = 10;
    sheetDetail.getColumn(6).width = 10;
    sheetDetail.getColumn(7).width = 20;
    sheetDetail.getColumn(8).width = 12;

    const fontBoldRowsDetailSheet = [
      { row: 1, size: 13 },
      { row: 7, size: 14 },
      { row: 9, size: 10 },
      { row: 11, size: 10 },
      { row: 12, size: 10 },
    ];

    fontBoldRowsDetailSheet.forEach((item) => {
      sheetDetail.getRow(item.row).font = {
        name: 'Arial',
        family: 4,
        size: item.size,
        bold: true,
      };
    });

    const alignCenterRowsDetailSheet = [
      11,
      12,
      showResultDetailSheet + 2,
      showResultDetailSheet + 3,
    ];
    alignCenterRowsDetailSheet.forEach((row) => {
      sheetDetail.getRow(row).alignment = { vertical: 'middle', horizontal: 'center' };
    });

    for (let i = 9; i <= 11; i++) {
      sheetDetail.getColumn(i).numFmt = '#,##0';
      sheetDetail.getColumn(i).width = 10;
    }
    for (let i = 7; i <= 3000; i++) {
      sheetDetail.getRow(i).height = 21;
    }

    sheetDetail.getRow(4).height = 5;
    sheetDetail.getRow(5).height = 5;
    sheetDetail.getRow(6).height = 10;

    // ================= Style ==========================
    try {
      await workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = `Bang_Tinh_Chi_Phi_KH_Mau_2_${customerSelected?.name}_Tu_${moment(
          filter?.DateFrom
        ).format('DD-MM-YYYY')}_Den_${moment(filter?.DateTo).format(
          'DD-MM-YYYY'
        )}_Xuat_Excel_Luc_${moment().format('DD-MM-YY HH:mm')}.xlsx`; // name excel
        // anchor.download = `${moment().format('HH:mm')}.xlsx`;
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false);
    }
  };

  const handleChangeCustomer = (value) => {
    if (!value) {
      setCustomerId('');
      setCustomerSelected(null);
      setCompany(null);
      setBills(null);
      setDetail([]);
      return;
    }
    const dataCustomer = customers.find((item) => item.id === value);
    setCustomerId(value);
    setCustomerSelected(dataCustomer);
  };

  return (
    <>
      <h1>BẢNG TÍNH CHI PHÍ KHÁCH HÀNG MẪU 2</h1>

      <div
        style={{
          backgroundColor: 'rgb(234, 238, 243)',
          boxShadow:
            'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
          borderRadius: '10px',
          fontSize: '12px',
          padding: '20px 10px',
          marginTop: '15px',
          marginBottom: '15px',
        }}
      >
        <Space>
          <div>
            <b
              style={{
                marginLeft: '10px',
              }}
            >
              Chọn ngày:
            </b>
            <RangeDateFilter query={filter} onChange={setFilter} allowClear={false} />
          </div>
          <div>
            <b>
              Khách hàng:{' '}
              {!customerId && (
                <i
                  style={{
                    color: 'red',
                    fontSize: '12px',
                  }}
                >
                  Bạn phải chọn khách hàng
                </i>
              )}
            </b>
            <br />
            <Selection
              status={!customerId ? 'error' : ''}
              data={customers}
              placeholder='Khách hàng'
              fieldValue='name'
              width={400}
              value={customerId}
              onChange={handleChangeCustomer}
              onSearch={getCustomer}
            />
          </div>
          <div>
            <br />
            <Button
              type='primary'
              loading={loading}
              icon={<DownloadOutlined />}
              style={{ padding: '0px 20px', marginLeft: '10px' }}
              disabled={!company || !bills || !detail?.length}
              onClick={exportExcelFile}
            >
              Xuất Báo Cáo
            </Button>
          </div>
        </Space>
      </div>
      <TableDetail dataTable={detail || []} loading={loading} />
    </>
  );
};

export { ReportAccountantsCustomerExpenseBillForUI };
