import React, { useState } from 'react';
import {
  Table,
  Typography,
  Tooltip,
  Button,
  //  Modal,
  message,
} from 'antd';
import {
  // FormOutlined,
  CloseSquareOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { DATE_TIME_FORMAT } from '~commons/constants';
import Utils from '~commons/utils';
// import { ChangeAssignDateForm } from './change-assign-date-form';
import ApiServices from '~services';

const SHOW_CM_TASK_NAMES = ['Lấy Hàng Xuất', 'Giao Hàng Xuất'];
const SHOW_SI_TASK_NAMES = ['Lấy Rỗng Xuất'];
const SHOW_HHLC_TASK_NAMES = ['Lấy Hàng Nhập'];
const SHOW_TRADE_CONT_NAMES = ['Lấy Hàng Nhập', 'Lấy Rỗng Nhập', 'Lấy Hàng Xuất', 'Giao Hàng Xuất'];

const { Text } = Typography;

const getTradeCar = (record) =>
  SHOW_TRADE_CONT_NAMES.includes(record.name) && record.objCont?.trace;

const TableContentView = ({ loading, data, dataExtras, assignDate, reload }) => {
  // const [showModalChangeAssignDate, setShowModalAssignDate] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(false);

  // const handleChangeAssignDate = (dataRow) => {
  //   setShowModalAssignDate(dataRow);
  // };
  // const handleCancelChangeAssignDate = () => {
  //   setShowModalAssignDate(null);
  // };

  const reloadAfterChange = () => {
    // handleCancelChangeAssignDate();
    reload && reload();
  };

  const handleDeleteTransit = async (value) => {
    if (loadingDelete) return;
    setLoadingDelete(true);
    return ApiServices.deleteAssignTransit(value?.assignId)
      .then((res) => {
        if (res?.data?.data === 'success') {
          message.success('Xóa Chuyển Kho thành công');
          reloadAfterChange();
        } else {
          message.error('Xóa Chuyển Kho thất bại');
        }
      })
      .catch((err) => {
        console.log({ err });
        message.error('Thao tác thất bại, Vui lòng thử lại sau!: ', err);
      })
      .finally(() => {
        setLoadingDelete(false);
      });
  };
  const handleDeleteAssignTour = async (value) => {
    console.log({ value });
    if (loadingDelete) return;
    setLoadingDelete(true);
    return ApiServices.deleteAssign(value?.assignId)
      .then((res) => {
        if (res?.data?.data === 'success') {
          message.success('Xóa Lệnh Điều Xe thành công');
          reloadAfterChange();
        } else {
          message.error('Xóa Lệnh Điều Xe thất bại');
        }
      })
      .catch((err) => {
        console.log({ err });
        message.error('Thao tác thất bại, Vui lòng thử lại sau!: ', err);
      })
      .finally(() => {
        setLoadingDelete(false);
      });
  };

  const renderActualDate = () => {
    return <div>{assignDate ? Utils.formatDate(assignDate) : ''}</div>;
    // return (
    //   <Tooltip title='Chỉnh sửa ngày điều xe' color='blue'>
    //     <Button type='link' icon={<FormOutlined />} onClick={() => handleChangeAssignDate(record)}>
    //       {assignDate ? Utils.formatDate(assignDate) : ''}
    //     </Button>
    //   </Tooltip>
    // );
  };
  const renderDeleteTransit = (value, record) => {
    if (record?.isSupportAssign)
      return (
        <div
          style={{
            textAlign: 'center',
            color: 'blue',
          }}
        >
          Điều Xe Hổ Trợ
        </div>
      );
    if (!value)
      return (
        <Tooltip title='Xóa Lệnh Điều Xe' color='blue'>
          <Button
            type='link'
            danger
            className={record?.classRowGroupAssignId ? 'order-from-ONP' : ''}
            icon={<CloseSquareOutlined />}
            onClick={() => handleDeleteAssignTour(record)}
          >
            Xóa Lệnh ĐX
          </Button>
        </Tooltip>
      );
    return (
      <Tooltip title='Xóa Chuyển Kho' color='blue'>
        <Button
          type='link'
          icon={<CloseSquareOutlined />}
          onClick={() => handleDeleteTransit(record)}
        >
          Xóa CK
        </Button>
      </Tooltip>
    );
  };

  const columns = [
    {
      title: '-',
      dataIndex: 'newKey',
      align: 'center',
      with: 200,
    },
    {
      title: 'Khách Hàng',
      with: 140,
      dataIndex: 'customerName',
      align: 'center',
    },
    {
      title: 'Số Mooc',
      dataIndex: 'romoocCode',
      align: 'center',
      with: 80,
    },
    {
      title: 'Công Việc',
      dataIndex: 'name',
      align: 'center',
      with: 140,
    },
    {
      title: 'Booking / Bill',
      dataIndex: 'code',
      align: 'center',
      with: 120,
      render: (value, record) => {
        if (!value) return '';
        let res;
        if (value) {
          res = value.split('/');
          res.pop();
          res = res.join('/');
        }
        let startSubno = '';
        let endSubno = '';
        if (res) {
          const splitText = res.split('/');
          const splitTextLength = splitText?.length || 0;
          switch (splitTextLength) {
            case 3:
              startSubno = `${splitText[0]}`;
              endSubno = `/${splitText[1]}/${splitText[2]}`;
              break;
            case 4:
              startSubno = `${splitText[0]}`;
              endSubno = `/${splitText[1]}/${splitText[2]}/${splitText[3]}`;
              break;
            case 5:
              startSubno = `${splitText[0]}/${splitText[1]}`;
              endSubno = `/${splitText[2]}/${splitText[3]}/${splitText[4]}`;
              break;
            default:
              break;
          }
        }
        const cm =
          SHOW_CM_TASK_NAMES.includes(record.name) &&
          record.objCont?.objOrders?.[0]?.book_CutOffDateTime;
        const si =
          SHOW_SI_TASK_NAMES.includes(record.name) &&
          record.objCont?.objOrders?.[0]?.book_SIDateTime;
        const lc =
          SHOW_HHLC_TASK_NAMES.includes(record.name) &&
          record.objCont?.objOrders?.[0]?.bill_LastFreeDayDEM;

        return (
          <>
            {/* {res} */}
            <div>{startSubno}</div>
            <div>{endSubno}</div>
            {cm && <div className='blue'>{`CM: ${moment(cm).format(DATE_TIME_FORMAT)}`}</div>}
            {si && <div className='blue'>{`SI: ${moment(si).format(DATE_TIME_FORMAT)}`}</div>}
            {lc && <div className='blue'>{`HHLC: ${moment(lc).format(DATE_TIME_FORMAT)}`}</div>}
          </>
        );
      },
    },
    {
      title: 'Khởi Hành',
      dataIndex: 'beginLocationName',
      align: 'center',
      with: 140,
    },
    {
      title: 'Kết Thúc',
      dataIndex: 'endLocationName',
      align: 'center',
      with: 140,
    },
    {
      title: 'Số Km',
      dataIndex: 'distance',
      with: 100,
      align: 'center',
    },
    {
      title: 'Số Cont',
      dataIndex: 'objCont',
      align: 'center',
      with: 140,
      render: (value, record) => {
        if (!value) return '';
        const tradeCar = getTradeCar(record) || '';
        return `${value.contNumber || ''} ${tradeCar && `- ${tradeCar}`}`;
      },
    },
    {
      title: 'Ghi Chú',
      dataIndex: 'note',
      with: 140,
    },
    {
      title: 'Ngày điều xe',
      dataIndex: 'note',
      align: 'center',
      with: 130,
      render: renderActualDate,
    },
    {
      title: '#',
      dataIndex: 'assignTransit',
      with: 80,
      // fixed: 'right',
      render: renderDeleteTransit,
    },
  ];

  const handleSummary = (pageData) => {
    let sumTotalKm = 0;

    pageData.forEach((page) => {
      sumTotalKm += page?.distance;
    });
    return (
      <>
        <Table.Summary.Row
          style={{
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <Table.Summary.Cell index={0}></Table.Summary.Cell>
          <Table.Summary.Cell index={1}></Table.Summary.Cell>
          <Table.Summary.Cell index={2}></Table.Summary.Cell>
          <Table.Summary.Cell index={3}></Table.Summary.Cell>
          <Table.Summary.Cell index={4}></Table.Summary.Cell>
          <Table.Summary.Cell index={5}></Table.Summary.Cell>
          <Table.Summary.Cell index={6}>
            <Text type='danger'>Tổng Km:</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={7}>
            <span className='red'>{sumTotalKm}</span>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={8}>
            <Text type='danger'></Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={9}>
            <Text type='danger'></Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={10}>
            <Text type='danger'></Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={11}>
            <Text type='danger'></Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </>
    );
  };

  const renderRowClassName = (record) => {
    if (record?.classRowGroupAssignId) {
      return '';
    } else if (record.isSupportAssign) {
      return 'assign-support';
    } else {
      return 'order-from-ONP';
    }
  };

  const dataTable = data.concat(dataExtras);
  const dataEnd = dataTable?.map((newItem, newIndex) => {
    newItem.newKey = newIndex + 1;
    return newItem;
  });

  return (
    <>
      <Table
        columns={columns}
        dataSource={dataEnd || []}
        loading={loading}
        pagination={false}
        bordered={true}
        rowKey='id'
        size='small'
        summary={handleSummary}
        rowClassName={renderRowClassName}
        // scroll={{
        //   x: '100vw',
        // }}
      />
      {/* <Modal
        title='Đổi ngày điều xe:'
        open={!!showModalChangeAssignDate}
        onCancel={handleCancelChangeAssignDate}
        footer={null}
      >
        <ChangeAssignDateForm
          dataSelected={showModalChangeAssignDate}
          handleCancel={handleCancelChangeAssignDate}
          assignDate={assignDate}
          reloadAfterChange={reloadAfterChange}
        />
      </Modal> */}
    </>
  );
};

export { TableContentView };
