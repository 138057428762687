import React, { useRef, useEffect, useState } from 'react';
import { Button, Popconfirm, message, Input, Space } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { TableLayout, UploadExcelButton } from '~components';
import { FormData } from './form-data';
import DistanceFields from './fields-data';
import ApiServices from '~services';
import Utils from '~commons/utils';

const EXCEL_TEMPLATE_URL = Utils.getAppUrl() + 'Files/Import_Excel/Distance.xlsx';

const Distances = () => {
  const defaultTabPanels = [
    {
      title: 'Detail',
      content: 'detail content',
    },
  ];
  const typingTimeoutref = useRef(null);
  const [loading, setLoading] = useState(false);
  const [isLoadingTab, setIsLoadingTab] = useState(false);
  const [selected, setSelected] = useState('');
  const [Distances, setDistances] = useState([]);
  const [tabPanels, setTabPanels] = useState(defaultTabPanels);
  const [titleTab, setTitleTab] = useState('');
  const [fields, setFields] = useState({});
  console.log(fields);
  const [loadingExport, setLoadingExport] = useState(false);

  const onChangePagination = (page, size) => {
    let filterTemp = filter;
    filterTemp.PageNumber = page;
    filterTemp.PageSize = size;
    setFilter(filterTemp);
    getDistances();
  };

  const [pagination, setPagination] = useState({
    total: 1,
    showSizeChanger: true,
    onChange: onChangePagination,
  });
  const [filter, setFilter] = useState({
    PageNumber: 1,
    PageSize: 20,
    Text: '',
    Columns: '',
  });

  const [searchByColumn, setSearchByColumn] = useState({
    locationFromName: '',
    locationToName: '',
    distanceKm: '',
    loss: '',
  });

  const getDistances = async () => {
    let dataFilterSend = { ...filter };
    if (Object.keys(dataFilterSend.Columns).length === 0) {
      delete dataFilterSend['Columns'];
    } else {
      const columnJSONSTRING = JSON.stringify(dataFilterSend.Columns);
      dataFilterSend.Columns = columnJSONSTRING;
    }

    try {
      setLoading(true);
      let { data } = await ApiServices.getDistances(dataFilterSend);
      setLoading(false);
      let pag = pagination;
      pag.total = data?.totalRecords || 0;
      pag.pageSize = data?.pageSize;
      pag.current = data?.pageNumber;

      data = data?.data || [];
      setDistances(data);
      setPagination(pag);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getDistances();
  }, []);

  const handleSearchColumnFn = (event, name) => {
    const filterTmp = filter;
    const val = event.target.value;
    let _searchByColumn = { ...searchByColumn };
    _searchByColumn[`${name}`] = val;
    setSearchByColumn(_searchByColumn);
    // Clear timeout
    if (typingTimeoutref.current) {
      clearTimeout(typingTimeoutref.current);
    }
    typingTimeoutref.current = setTimeout(() => {
      if (val.length > 0) {
        filter.PageNumber = 1;
        let searchColumnFn = { ...filter.Columns };
        searchColumnFn[`${name}`] = val;
        filter.Columns = searchColumnFn;

        setFilter({ ...filterTmp, PageNumber: 1 });
        getDistances();
      }
      if (val.length === 0) {
        delete filter.Columns[`${name}`];
        setFilter({ ...filterTmp, PageNumber: 1 });
        getDistances();
      }
    }, 1000);
  };

  const setFieldsForm = async (value) => {
    const tireFields = await DistanceFields(value);
    await setFields(tireFields);
    return tireFields;
  };
  const reload = async () => {
    setSelected(null);
    await getDistances();
  };

  const getUpdatedFormData = async (
    value,
    isNew = false,
    id = null,
    idStartLocation = null,
    nameStartLocation = null,
    idEndLocation = null,
    nameEndLocation = null
  ) => {
    const formData = await (
      <FormData
        fields={value}
        setLoading={setIsLoadingTab}
        isNew={isNew}
        id={id}
        idStartLocation={idStartLocation}
        nameStartLocation={nameStartLocation}
        idEndLocation={idEndLocation}
        nameEndLocation={nameEndLocation}
        reload={reload}
        onChange={setFields}
      />
    );
    return setTabPanels([
      {
        title: 'Thông Tin Chi Tiết',
        content: formData,
      },
    ]);
  };

  const onRowSelected = async (value) => {
    await setIsLoadingTab(true);
    await setTitleTab('');
    await setFieldsForm(value)
      .then((res) => {
        return getUpdatedFormData(
          res,
          false,
          value.id,
          value.locationFromCode,
          value.locationFromName,
          value.locationToCode,
          value.locationToName
        );
      })
      .then(() => setSelected(value));
    setIsLoadingTab(false);
  };

  const deleteDistance = async (record) => {
    try {
      const res = await ApiServices.deleteDistance(record.id);
      if (res.data) {
        message.success('Xoá Cự Ly thành công');
        return reload();
      }
      message.error('Xoá Cự Ly thất bại, vui lòng thử lại sau!');
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      width: 150,
      align: 'center',
      render: (value, record) => <a onClick={() => onRowSelected(record)}>Chi tiết</a>,
    },
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Địa Điểm Bắt Đầu</div>
          <Input
            placeholder='Search'
            allowClear
            value={searchByColumn.locationFromName}
            onChange={(event) => handleSearchColumnFn(event, 'locationFromName')}
          />
        </span>
      ),
      dataIndex: 'locationFromName',
      width: 200,
      align: 'center',
    },
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Địa Điểm Kết Thúc</div>
          <Input
            placeholder='Search'
            allowClear
            value={searchByColumn.locationToName}
            onChange={(event) => handleSearchColumnFn(event, 'locationToName')}
          />
        </span>
      ),
      dataIndex: 'locationToName',
      width: 200,
      align: 'center',
    },
    {
      title: 'Khoảng cách (Km)',
      dataIndex: 'distanceKm',
      width: 100,
      align: 'center',
    },
    {
      title: 'Hao phí',
      dataIndex: 'loss',
      width: 100,
      align: 'center',
    },
    {
      title: 'Action',
      fixed: 'right',
      width: 90,
      align: 'center',
      render: (record) => (
        <Popconfirm
          onConfirm={() => deleteDistance(record)}
          title='Xoá Cự Ly này?'
          okText='Yes'
          cancelText='No'
        >
          <a style={{ color: 'red' }} href='#'>
            Xoá
          </a>
        </Popconfirm>
      ),
    },
  ];

  const createTire = async () => {
    await setTitleTab('');
    await setFieldsForm({})
      .then((res) => getUpdatedFormData(res, true))
      .then(() => setSelected({ isNew: true }));
    await setIsLoadingTab(false);
  };
  const onUploadExcel = (file) => {
    if (loading) return;
    setLoading(true);
    ApiServices.importDistance(file)
      .then((res) => {
        if (!res.data?.error) {
          message.success(res?.data?.message);
          reload();
        }
      })
      .catch((err) => {
        console.error('UPLOAD IMPORT CARR ERROR', err);
        message.error('Thao tác thất bại, Vui lòng thử lại sau!');
      })
      .finally(() => setLoading(false));
  };

  const handleExportExcel = async (query) => {
    if (loadingExport) return;
    const dataSend = { ...query, export: true };
    setLoadingExport(true);
    try {
      const { data } = await ApiServices.exportExcelDistance(dataSend);
      if (data) {
        const outputFilename = `Danh_Sach_Cu_Ly.xlsx`;
        // If you want to download file automatically using link attribute.
        const url = URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        window.TmsNoti?.error('Export thất bại!', 'Không có dữ liệu');
      }
    } catch (err) {
      window.TmsNoti?.error('Export thất bại!');
    } finally {
      setLoadingExport(false);
    }
  };

  const headerContent = (
    <Space>
      <Button type='primary' onClick={createTire}>
        Thêm Mới
      </Button>
      <UploadExcelButton
        loading={loading}
        onChange={onUploadExcel}
        templateUrl={EXCEL_TEMPLATE_URL}
      />
      <Button
        type='primary'
        icon={<DownloadOutlined />}
        style={{ padding: '0px 20px', marginLeft: '10px' }}
        loading={loadingExport}
        disabled={!Distances?.length}
        onClick={() => handleExportExcel(filter)}
      >
        Export Excel
      </Button>
    </Space>
  );

  return (
    <TableLayout
      rowKey='id'
      columns={columns}
      data={Distances}
      loading={loading}
      selected={selected}
      closeDrawer={() => setSelected(null)}
      isLoadingTab={isLoadingTab}
      headerContent={headerContent}
      titleTab={titleTab}
      tabPanels={tabPanels}
      pagination={pagination}
      isDisplayCheckbox={false}
      headerLength='190px'
    />
  );
};

export { Distances };
