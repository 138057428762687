import { Button, Input, Popconfirm } from 'antd';
import Utils from '~commons/utils';
import styled from 'styled-components';
import { ContStatus } from '~components/common-status';
const SuccessButton = styled(Button)`
  padding: 0 8px;
  &.ant-btn-link:not([disabled]) {
    color: green;
  }
`;

export const getColumns = (setQuery, showListFile, changeToNotComplete, loadingChangeStatus) => [
  {
    title: '#',
    width: '130px',
    fixed: 'left',
    dataIndex: 'action',
    align: 'center',
    render: (_, record) => (
      <Popconfirm
        onConfirm={() => changeToNotComplete(record?.id)}
        title={`Bạn có chắc chắn đổi sang trạng thái "Chưa Hoàn Thành"?`}
        okText='Có'
        cancelText='Không'
        disabled={loadingChangeStatus}
      >
        <a href='#' className='red'>
          Chưa Hoàn Thành
        </a>
      </Popconfirm>
    ),
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Khách hàng</div>
      </span>
    ),
    sorter: true,
    dataIndex: 'customerName',
    showSorterTooltip: false,
    sortDirections: ['ascend', 'descend', 'ascend'],
    children: [
      {
        title: (
          <span style={{ textAlign: 'center' }}>
            <Input
              placeholder='Search'
              allowClear
              onChange={(event) => setQuery({ customerName: event.target.value })}
            />
          </span>
        ),
        width: '150px',
        dataIndex: 'customerName',
        align: 'center',
      },
    ],
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Hãng tàu</div>
      </span>
    ),
    sorter: true,
    dataIndex: 'shippingLine',
    showSorterTooltip: false,
    sortDirections: ['ascend', 'descend', 'ascend'],
    children: [
      {
        title: (
          <span style={{ textAlign: 'center' }}>
            <Input
              placeholder='Search'
              allowClear
              onChange={(event) => setQuery({ shippingLine: event.target.value })}
            />
          </span>
        ),
        width: '130px',
        dataIndex: 'shippingLine',
        align: 'center',
      },
    ],
  },

  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>SubNo</div>
      </span>
    ),
    sorter: true,
    dataIndex: 'subnoNumber',
    showSorterTooltip: false,
    sortDirections: ['ascend', 'descend', 'ascend'],
    children: [
      {
        title: (
          <span style={{ textAlign: 'center' }}>
            <Input
              placeholder='Search'
              allowClear
              onChange={(event) => setQuery({ subnoNumber: event.target.value })}
            />
          </span>
        ),
        width: '180px',
        dataIndex: 'subnoNumber',
        render: (value, record) => (
          <>
            {value}
            <SuccessButton type='link' onClick={() => showListFile(record)}>
              File Đk
            </SuccessButton>
          </>
        ),
      },
    ],
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Ngày Booking</div>
      </span>
    ),
    width: '90px',
    dataIndex: 'createdOn',
    sorter: true,
    showSorterTooltip: false,
    sortDirections: ['ascend', 'descend', 'ascend'],
    render: (value, record) => Utils.formatDateHourFn(value, record),
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Số cont</div>
        <Input
          placeholder='Search'
          allowClear
          onChange={(event) => setQuery({ contNumber: event.target.value })}
        />
      </span>
    ),
    width: '120px',
    align: 'center',
    dataIndex: 'contNumber',
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Số seal</div>
        <Input
          placeholder='Search'
          allowClear
          onChange={(event) => setQuery({ contNumber: event.target.value })}
        />
      </span>
    ),
    width: '100px',
    dataIndex: 'sealNumber',
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Trạng thái</div>
      </span>
    ),
    width: '120px',
    textAlign: 'center',
    align: 'center',
    dataIndex: 'status',
    render: (value, record) => (
      <>
        <ContStatus status={value} revisedText='Đang xử lý' />
        {record.partnerName && (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            ({record.partnerName})
          </div>
        )}
      </>
    ),
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Cắt Máng</div>
      </span>
    ),
    width: '90px',
    dataIndex: 'book_CutOffDateTime',
    sorter: true,
    showSorterTooltip: false,
    sortDirections: ['ascend', 'descend', 'ascend'],
    render: (value, record) => Utils.renderWarningDate(value, record),
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Cắt SI</div>
      </span>
    ),
    width: '90px',
    dataIndex: 'book_SIDateTime',
    sorter: true,
    showSorterTooltip: false,
    sortDirections: ['ascend', 'descend', 'ascend'],
    render: (value, record) => Utils.renderWarningDate(value, record),
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Ngày YCGC</div>
      </span>
    ),
    width: '90px',
    dataIndex: 'book_DeliveryContRequestDate',
    sorter: true,
    showSorterTooltip: false,
    sortDirections: ['ascend', 'descend', 'ascend'],
    render: (value) => Utils.formatDate(value),
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Nơi Đóng Hàng</div>
      </span>
    ),
    width: '130px',
    dataIndex: 'lh',
    align: 'center',
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Hạ/Bãi T.Lý</div>
      </span>
    ),
    width: '130px',
    dataIndex: 'gh',
    align: 'center',
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Điểm Lấy Rỗng</div>
      </span>
    ),
    width: '130px',
    dataIndex: 'book_LocationDepotFromName',
    align: 'center',
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Nguồn</div>
        <Input
          placeholder='Search'
          allowClear
          onChange={(event) => setQuery({ source: event.target.value })}
        />
      </span>
    ),
    width: '80px',
    textAlign: 'center',
    align: 'center',
    dataIndex: 'source',
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Ghi chú khách hàng</div>
      </span>
    ),
    width: '200px',
    dataIndex: 'note',
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Ghi chú xe ngoài</div>
      </span>
    ),
    width: '200px',
    dataIndex: 'notePlan',
  },
];
