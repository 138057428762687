import React, { useEffect, useState, useCallback } from 'react';
const ExcelJS = require('exceljs');
import moment from 'moment';
import { debounce } from 'lodash';
import { Space, Button } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import ApiServices from '~services';
import { RangeDateFilter, Selection } from '~components';
import { ReadNumber } from '~commons/read-number';
import logo from '~assets/images/logo.png';
import Utils from '~commons/utils';
import { TableDetail } from './table-detail';

const toDataURL = (url) => {
  const promise = new Promise((resolve) => {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.readAsDataURL(xhr.response);
      reader.onloadend = function () {
        resolve({ base64Url: reader.result });
      };
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  });

  return promise;
};

const DEFAULT_QUERIES = {
  DateFrom: moment().startOf('month').format(),
  DateTo: moment().endOf('date').format(),
};

const AccountantsPartnerExpense = () => {
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState(null);
  const [summary, setSummary] = useState(null);
  const [detail, setDetail] = useState([]);
  const [filter, setFilter] = useState(DEFAULT_QUERIES);
  ////
  const [partners, setPartners] = useState([]);
  const [partnerSelectedId, setPartnerSelectedId] = useState(null);
  const [partnerSelected, setPartnerSelected] = useState({});

  const getPartners = async (query = '') => {
    return ApiServices.getPartnerByCompany({ query })
      .then(({ data }) => {
        setPartners(data?.data || []);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    getPartners();
  }, []);

  const getData = async (query = DEFAULT_QUERIES, partnerId) => {
    setLoading(true);
    const newQuery = { ...query };
    return ApiServices.getAccountantsByPartnerExpense(partnerId, newQuery)
      .then((res) => {
        if (res?.data?.data?.company) {
          setCompany(res?.data?.data?.company);
        } else {
          setCompany(null);
        }
        if (res?.data?.data?.summary) {
          setSummary(res?.data?.data?.summary);
        } else {
          setSummary(null);
        }
        if (res?.data?.data?.detail) {
          const dataMap = res?.data?.data?.detail?.map((item, indexItem) => {
            let listExpense = {
              nang: 0,
              ha: 0,
              thanhLyTK: 0,
              luuBai: 0,
              luuCont: 0,
              chuyenTai: 0,
              phuThu: 0,
              muaSeal: 0,
              chuyenContRottau: 0,
              dO: 0,
              veSinh: 0,
              chiPhiDiDuong: 0,
              dieuDo: 0,
              linhTinh: 0,
              congCang: 0,
              cauDuong: 0,
              vaVo: 0,
              thayRuot: 0,
              muaPhuTung: 0,
              thayYem: 0,
              faxPhoto: 0,
              doiLenh: 0,
              totals: 0,
            };
            item?.objExpense?.map((el) => {
              switch (el?.code) {
                case 'nang':
                  listExpense.nang = el?.total;
                  listExpense.totals = listExpense.totals + el.total;
                  return;
                case 'ha':
                  listExpense.ha = el?.total;
                  listExpense.totals = listExpense.totals + el.total;
                  return;
                case 'thanh-ly-tk':
                  listExpense.thanhLyTK = el?.total;
                  listExpense.totals = listExpense.totals + el.total;
                  return;
                case 'luu-bai':
                  listExpense.luuBai = el?.total;
                  listExpense.totals = listExpense.totals + el.total;
                  return;
                case 'luu-cont':
                  listExpense.luuCont = el?.total;
                  listExpense.totals = listExpense.totals + el.total;
                  return;
                case 'chuyen-tai':
                  listExpense.chuyenTai = el?.total;
                  listExpense.totals = listExpense.totals + el.total;
                  return;
                case 'phu-thu':
                  listExpense.phuThu = el?.total;
                  listExpense.totals = listExpense.totals + el.total;
                  return;
                case 'mua-seal':
                  listExpense.muaSeal = el?.total;
                  listExpense.totals = listExpense.totals + el.total;
                  return;
                case 'chuyen-cont-rot-tau':
                  listExpense.chuyenContRottau = el?.total;
                  listExpense.totals = listExpense.totals + el.total;
                  return;
                case 'do':
                  listExpense.dO = el?.total;
                  listExpense.totals = listExpense.totals + el.total;
                  return;
                case 've-sinh':
                  listExpense.veSinh = el?.total;
                  listExpense.totals = listExpense.totals + el.total;
                  return;
                case 'chi-phi-di-duong':
                  listExpense.chiPhiDiDuong = el?.total;
                  listExpense.totals = listExpense.totals + el.total;
                  return;
                case 'dieu-do':
                  listExpense.dieuDo = el?.total;
                  listExpense.totals = listExpense.totals + el.total;
                  return;
                case 'linh-tinh':
                  listExpense.linhTinh = el?.total;
                  listExpense.totals = listExpense.totals + el.total;
                  return;
                case 'cong-cang':
                  listExpense.congCang = el?.total;
                  listExpense.totals = listExpense.totals + el.total;
                  return;
                case 'cau-duong':
                  listExpense.cauDuong = el?.total;
                  listExpense.totals = listExpense.totals + el.total;
                  return;
                case 'va-vo':
                  listExpense.vaVo = el?.total;
                  listExpense.totals = listExpense.totals + el.total;
                  return;
                case 'thay-ruot':
                  listExpense.thayRuot = el?.total;
                  listExpense.totals = listExpense.totals + el.total;
                  return;
                case 'mua-phu-tung':
                  listExpense.muaPhuTung = el?.total;
                  listExpense.totals = listExpense.totals + el.total;
                  return;
                case 'thay-yem':
                  listExpense.thayYem = el?.total;
                  listExpense.totals = listExpense.totals + el.total;
                  return;
                case 'fax-photo':
                  listExpense.faxPhoto = el?.total;
                  listExpense.totals = listExpense.totals + el.total;
                  return;
                case 'doi-lenh':
                  listExpense.doiLenh = el?.total;
                  listExpense.totals = listExpense.totals + el.total;
                  return;
                default:
                  break;
              }
            });
            //
            item.keyLabel = `${indexItem + 1}`;
            item.key = indexItem + 1;
            return { ...item, ...listExpense };
          });

          setDetail(dataMap || []);
        } else {
          setDetail([]);
        }
      })
      .catch((err) => {
        setCompany(null);
        setSummary(null);
        setDetail([]);
        console.error('get Accountants By CustomerExpense Error: ', err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const timeoutGetData = useCallback(
    debounce((newFilter, newPartnerSelectedId) => {
      getData(newFilter, newPartnerSelectedId);
    }, 500),
    []
  );
  useEffect(() => {
    if (partnerSelectedId) {
      timeoutGetData(filter, partnerSelectedId);
    }
  }, [filter, partnerSelectedId]);

  const exportExcelFile = async () => {
    if (loading) return;
    setLoading(true);
    const workbook = new ExcelJS.Workbook();
    //  ==============  SUMMARY SHEET ==========================
    // Ten sheet
    const sheetSummary = workbook.addWorksheet('Summary', {
      properties: { tabColor: { argb: 'FF00FF00' } },
      views: [{ state: 'frozen', ySplit: 5, activeCell: 'A1', showGridLines: false }],
    });
    sheetSummary.properties.defaultRowHeight = 15;
    sheetSummary.properties.defaultColWidth = 15;
    // Add Image
    // const pathLogo = company?.logo?.split('~')[1];
    // const urlLogo = `${process.env.REACT_APP_API_URL}${pathLogo}`;
    // const resultTest = await toDataURL(urlLogo);
    // const splittedTest = urlLogo.split('.');
    // const extNameTest = splittedTest[splittedTest.length - 1];
    const resultTest = await toDataURL(logo);
    const extNameTest = 'png';
    // --------- Summary Sheet --------------------
    const imageId2Summary = workbook.addImage({
      base64: resultTest.base64Url,
      extension: extNameTest,
    });

    sheetSummary.addImage(imageId2Summary, {
      tl: { col: 2, row: 0 },
      ext: { width: 150, height: 100 },
    });
    sheetSummary.getRow(1).font = {
      name: 'Arial',
      family: 4,
      size: 13,
      bold: true,
    };
    sheetSummary.getCell('D1').value = company?.name || '';
    sheetSummary.getCell('D2').value = company?.address || '';
    sheetSummary.getCell('D3').value = company?.phone || '';

    sheetSummary.getRow(6).commit(); // now rows 1 and two are committed.
    sheetSummary.getRow(7).font = {
      name: 'Arial',
      family: 4,
      size: 13,
      bold: true,
    };
    sheetSummary.getCell('E7').value = `BÁO CÁO CHI PHÍ XE NGOÀI Từ_${moment(
      filter?.DateFrom
    ).format('DD/MM/YYYY')}_Đến_${moment(filter?.DateTo).format('DD/MM/YYYY')}`;
    sheetSummary.addRow(['']); // blank Row
    sheetSummary.addRow([
      '',
      'STT',
      'Biển Số Xe',
      'Số Lượng Cont',
      '',
      '',
      'Phí Neo',
      'Cước Vận Chuyển',
      'Chi Phí',
      '', // Lưu Con
      '', // Chuyen Tai
      '', // Ve Sinh
      '', // Nang
      '', // Ha
      '', // Phu Thu
      '', // #8
      'Số Tiền',
    ]);
    sheetSummary.addRow([
      '',
      '',
      '',
      'Lấy Dùm',
      'Hạ Dùm',
      'Trọn Tua',
      '',
      '',
      'Lưu Bãi', // Luu Bãi
      'Lưu Cont', // Lưu Con
      'Chuyển Tải', // Chuyen Tai
      'Vệ Sinh', // Ve Sinh
      'Nâng', // Nang
      'Hạ', // Ha
      'Phụ Thu', // Phu Thu
      '#8', // #8
      '',
    ]);
    const moneyTotal =
      summary?.luuBai +
      summary?.luuCont +
      summary?.chuyenTai +
      summary?.veSinh +
      summary?.nang +
      summary?.ha +
      summary?.phuThu;
    // Add Body
    sheetSummary.addRow([
      '',
      1,
      summary?.name || '',
      summary?.conts?.totalUp,
      summary?.conts?.totalDown,
      summary?.conts?.totalTour,
      0, // Phí neo = 0 vì k có làm Hợp đồng
      0, // Cước Vận Chuyển = 0 vì k có làm Hợp đồng
      summary?.luuBai, // Luu Bãi
      summary?.luuCont, // Lưu Con
      summary?.chuyenTai, // Chuyen Tai
      summary?.veSinh, // Ve Sinh
      summary?.nang, // Nang
      summary?.ha, // Ha
      summary?.phuThu, // Phu Thu
      0, // #8
      moneyTotal,
    ]);
    // Add Sum
    sheetSummary.addRow([
      '',
      'Tổng Cộng:',
      '',
      summary?.conts?.totalUp,
      summary?.conts?.totalDown,
      summary?.conts?.totalTour,
      0, // Phí neo = 0 vì k có làm Hợp đồng
      0, // Cước Vận Chuyển = 0 vì k có làm Hợp đồng
      summary?.luuBai, // Luu Bãi
      summary?.luuCont, // Lưu Con
      summary?.chuyenTai, // Chuyen Tai
      summary?.veSinh, // Ve Sinh
      summary?.nang, // Nang
      summary?.ha, // Ha
      summary?.phuThu, // Phu Thu
      0, // #8
      moneyTotal,
    ]);
    // ----------  Styles --------------
    sheetSummary.getColumn(1).width = 5;
    sheetSummary.getColumn(2).width = 5;
    sheetSummary.getColumn(3).width = 25;
    sheetSummary.getColumn(8).width = 20;

    const listMerge1 = ['B', 'C', 'G', 'H', 'Q'];
    listMerge1.forEach((item) => {
      sheetSummary.mergeCells(`${item}9:${item}10`);
      sheetSummary.getCell(`${item}9`).border = {
        top: { style: 'thin', color: { argb: '006699' } },
        left: { style: 'thin', color: { argb: '006699' } },
        right: { style: 'thin', color: { argb: '006699' } },
        bottom: { style: 'thin', color: { argb: '006699' } },
      };
      sheetSummary.getCell(`${item}9`).fill = {
        type: 'gradient',
        gradient: 'angle',
        stops: [
          { position: 0, color: { argb: '96B6C5' } },
          { position: 1, color: { argb: '96B6C5' } },
        ],
      };
    });
    const listMerge2 = [
      {
        start: 'D',
        end: 'F',
      },
      {
        start: 'I',
        end: 'P',
      },
    ];
    listMerge2.forEach((item) => {
      sheetSummary.mergeCells(`${item.start}9:${item.end}9`);
      sheetSummary.getCell(`${item.start}9`).border = {
        top: { style: 'thin', color: { argb: '006699' } },
        left: { style: 'thin', color: { argb: '006699' } },
        right: { style: 'thin', color: { argb: '006699' } },
        bottom: { style: 'thin', color: { argb: '006699' } },
      };
      sheetSummary.getCell(`${item.start}9`).fill = {
        type: 'gradient',
        gradient: 'angle',
        stops: [
          { position: 0, color: { argb: '96B6C5' } },
          { position: 1, color: { argb: '96B6C5' } },
        ],
      };
    });
    const listMerge3 = ['D', 'E', 'F', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P'];
    listMerge3.forEach((item) => {
      sheetSummary.getCell(`${item}10`).border = {
        top: { style: 'thin', color: { argb: '006699' } },
        left: { style: 'thin', color: { argb: '006699' } },
        right: { style: 'thin', color: { argb: '006699' } },
        bottom: { style: 'thin', color: { argb: '006699' } },
      };
      sheetSummary.getCell(`${item}10`).fill = {
        type: 'gradient',
        gradient: 'angle',
        stops: [
          { position: 0, color: { argb: '96B6C5' } },
          { position: 1, color: { argb: '96B6C5' } },
        ],
      };
    });
    const borderBodyList = [
      'B',
      'C',
      'D',
      'E',
      'F',
      'G',
      'H',
      'I',
      'J',
      'K',
      'L',
      'M',
      'N',
      'O',
      'P',
      'Q',
    ];
    borderBodyList.forEach((item) => {
      sheetSummary.getCell(`${item}11`).border = {
        top: { style: 'thin', color: { argb: '006699' } },
        left: { style: 'thin', color: { argb: '006699' } },
        right: { style: 'thin', color: { argb: '006699' } },
        bottom: { style: 'thin', color: { argb: '006699' } },
      };
    });
    borderBodyList.forEach((item) => {
      sheetSummary.getCell(`${item}12`).border = {
        top: { style: 'thin', color: { argb: '006699' } },
        left: { style: 'thin', color: { argb: '006699' } },
        right: { style: 'thin', color: { argb: '006699' } },
        bottom: { style: 'thin', color: { argb: '006699' } },
      };
      sheetSummary.getCell(`${item}12`).fill = {
        type: 'gradient',
        gradient: 'angle',
        stops: [
          { position: 0, color: { argb: '96B6C5' } },
          { position: 1, color: { argb: '96B6C5' } },
        ],
      };
    });

    sheetSummary.mergeCells('B12:C12');

    sheetSummary.getRow(9).alignment = { vertical: 'middle', horizontal: 'center' };
    sheetSummary.getRow(10).alignment = { vertical: 'middle', horizontal: 'center' };
    sheetSummary.getCell('B12').alignment = { vertical: 'middle', horizontal: 'right' };
    const setFontRows = [9, 10, 12];
    setFontRows.forEach((row) => {
      sheetSummary.getRow(row).font = {
        name: 'Arial',
        family: 2,
        size: 11,
        bold: true,
      };
    });
    for (let i = 9; i <= 17; i++) {
      sheetSummary.getColumn(i).numFmt = '#,##0';
      sheetSummary.getColumn(i).width = 15;
    }
    sheetSummary.getCell('C14').value = 'Số tiền bằng chữ:';
    sheetSummary.getCell('C14').fill = {
      type: 'gradient',
      gradient: 'angle',
      stops: [
        { position: 0, color: { argb: '96B6C5' } },
        { position: 1, color: { argb: '96B6C5' } },
      ],
    };
    sheetSummary.getCell('C14').alignment = { vertical: 'middle', horizontal: 'right' };
    sheetSummary.getCell('D14').value = ReadNumber(moneyTotal);
    //  ==============  DETAIL SHEET ==========================
    const customerSheet = workbook.addWorksheet(`${partnerSelected?.code}`, {
      properties: { tabColor: { argb: 'FF00FF00' } },
      views: [{ state: 'frozen', ySplit: 5, activeCell: 'A1', showGridLines: false }],
    });
    customerSheet.properties.defaultRowHeight = 15;
    customerSheet.properties.defaultColWidth = 15;

    customerSheet.addImage(imageId2Summary, {
      tl: { col: 1, row: 0 },
      ext: { width: 150, height: 100 },
    });
    customerSheet.getRow(1).font = {
      name: 'Arial',
      family: 4,
      size: 13,
      bold: true,
    };
    customerSheet.getCell('D1').value = company?.name || '';
    customerSheet.getCell('D2').value = company?.address || '';
    customerSheet.getCell('D3').value = company?.phone || '';

    customerSheet.getRow(6).commit(); // now rows 1 and two are committed.
    customerSheet.getRow(7).font = {
      name: 'Arial',
      family: 4,
      size: 13,
      bold: true,
    };
    customerSheet.getCell('E7').value = `BÁO CÁO CHI PHÍ XE NGOÀI Từ_${moment(
      filter?.DateFrom
    ).format('DD/MM/YYYY')}_Đến_${moment(filter?.DateTo).format('DD/MM/YYYY')}`;

    let sumTotal = 0;
    const arrDetail = await detail?.map((item) => {
      sumTotal += item?.totals;
      const dataItem = [
        item?.key, // STT
        Utils.displayOrderTypeOrder(item?.orderType), // Loại Hình
        item?.subnoNumber, // Số Book / Bill
        item?.customerName, // Khách hàng
        item?.createdOn && new Date(moment(item?.createdOn).format('YYYY-MM-DD')), // Ngày Nhận
        item?.contNumber, //Số Cont
        item?.contType === `20"` ? 1 : 0,
        item?.contType === '40C' ? 1 : 0,
        item?.contType === '40T' ? 1 : 0,
        item?.contType === `45"` ? 1 : 0,
        item?.deliveryAcctualDate &&
          new Date(moment(item?.deliveryAcctualDate).format('YYYY-MM-DD')), // Ngày Giao Cont
        item?.completeDate && new Date(moment(item?.completeDate).format('YYYY-MM-DD')), // Ngày Hạ Cont
        item?.layCont, // Nơi Lấy Cont
        item?.tmpCont || '', //Nơi Đóng/Rút Hàng
        item?.haCont, // Nơi Hạ Cont
        partnerSelected?.name, // Đối tác
        'Mooc Ngoài', // Số Mooc
        0, // Số Ngày Neo, = 0  là do k có làm, chỉ để cho vui
        0, // Phí Neo = 0  là do k có làm, chỉ để cho vui
        0, // Phí Vận Chuyển, = 0 vì k có làm hợp đồng
        item?.luuBai, // Lưu Bãi
        item?.luuCont, // Lưu Cont
        item?.chuyenTai, // Chuyển Tải
        item?.veSinh, // Vệ Sinh
        item?.nang, // Nâng
        item?.ha, // Hạ
        item?.phuThu, // Phụ Thu
        '', // #9
        '', // #12
        '', // #13
        '', //#14
        '', //#15
        '', //#16
        '', //#17
        '', // #18
        '', // #19
        item?.totals,
      ];
      return dataItem;
    });

    // Add Table
    await customerSheet.addTable({
      name: 'TableSummary',
      ref: 'B9',
      headerRow: true,
      totalsRow: true,
      style: {
        theme: 'TableStyleMedium23', // 23
        // showRowStripes: true,
      },
      columns: [
        { name: 'STT', totalsRowLabel: '', filterButton: false },
        { name: 'Loại Hình', filterButton: false },
        {
          name: 'Số Book / Bill',
          filterButton: false,
        },
        {
          name: 'Khách hàng',
          filterButton: false,
        },
        {
          name: 'Ngày Nhận',
          filterButton: false,
        },
        {
          name: 'Số Cont',
          filterButton: false,
        },
        {
          name: `20"`,
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: `40C`,
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: `40T`,
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: `45"`,
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Ngày Giao Cont',
          filterButton: false,
        },
        {
          name: 'Ngày Hạ Cont',
          filterButton: false,
        },
        {
          name: 'Nơi Lấy Cont',
          filterButton: false,
        },
        {
          name: 'Nơi Đóng/Rút Hàng',
          filterButton: false,
        },
        {
          name: 'Nơi Hạ Cont',
          filterButton: false,
        },
        {
          name: 'Đối tác',
          filterButton: false,
        },
        {
          name: 'Số Mooc',
          filterButton: false,
        },
        {
          name: 'Số Ngày Neo',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Phí Neo',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Phí Vận Chuyển',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Lưu Bãi',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Lưu Cont',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Chuyển Tải',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Vệ Sinh',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Nâng',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Hạ',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Phụ Thu',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: '#9',
          filterButton: false,
        },
        {
          name: '#12',
          filterButton: false,
        },
        {
          name: '#13',
          filterButton: false,
        },
        {
          name: '#14',
          filterButton: false,
        },
        {
          name: '#15',
          filterButton: false,
        },
        {
          name: '#16',
          filterButton: false,
        },
        {
          name: '#17',
          filterButton: false,
        },
        {
          name: '#18',
          filterButton: false,
        },
        {
          name: '#19',
          filterButton: false,
        },
        {
          name: 'Tổng',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
      ],
      rows: arrDetail,
    });

    // ----------  Styles --------------
    customerSheet.getColumn(1).width = 5;
    customerSheet.getColumn(2).width = 5;
    customerSheet.getColumn(4).width = 30;
    customerSheet.getColumn(5).width = 30;
    for (let i = 8; i <= 11; i++) {
      customerSheet.getColumn(i).width = 5;
    }
    for (let i = 14; i <= 17; i++) {
      customerSheet.getColumn(i).width = 25;
    }
    for (let i = 22; i <= 28; i++) {
      customerSheet.getColumn(i).numFmt = '#,##0';
      customerSheet.getColumn(i).width = 15;
    }
    for (let i = 29; i <= 37; i++) {
      customerSheet.getColumn(i).width = 15;
    }

    customerSheet.getColumn(38).numFmt = '#,##0';
    customerSheet.getColumn(38).width = 15;
    // sumTotal
    const tableLength = detail?.length;

    customerSheet.getCell(`C${tableLength + 12}`).value = 'Số tiền bằng chữ:';
    customerSheet.getCell(`C${tableLength + 12}`).fill = {
      type: 'gradient',
      gradient: 'angle',
      stops: [
        { position: 0, color: { argb: '96B6C5' } },
        { position: 1, color: { argb: '96B6C5' } },
      ],
    };
    customerSheet.getCell(`C${tableLength + 12}`).alignment = {
      vertical: 'middle',
      horizontal: 'right',
    };
    customerSheet.getCell(`D${tableLength + 12}`).value = ReadNumber(sumTotal);

    try {
      await workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = `BC_Chi_Tiet_Chi_Phi_KH_${partnerSelected?.name}_Tu_${moment(
          filter?.DateFrom
        ).format('DD-MM-YYYY')}_Den_${moment(filter?.DateTo).format(
          'DD-MM-YYYY'
        )}_Xuat_Excel_Luc_${moment().format('DD-MM-YY HH:mm')}.xlsx`; // name excel
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false);
    }
  };

  const handleChangePartner = (value) => {
    if (!value) {
      setPartnerSelectedId('');
      setPartnerSelected(null);
      setCompany(null);
      setSummary(null);
      setDetail([]);
      return;
    }
    const dataPartner = partners.find((item) => item.id === value);
    setPartnerSelectedId(value);
    setPartnerSelected(dataPartner);
  };

  return (
    <>
      <h1>BẢNG BÁO CÁO CHI PHÍ XE NGOÀI</h1>

      <div
        style={{
          backgroundColor: 'rgb(234, 238, 243)',
          boxShadow:
            'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
          borderRadius: '10px',
          fontSize: '12px',
          padding: '20px 10px',
          marginTop: '15px',
          marginBottom: '15px',
        }}
      >
        <Space>
          <div>
            <b
              style={{
                marginLeft: '10px',
              }}
            >
              Chọn ngày:
            </b>
            <RangeDateFilter query={filter} onChange={setFilter} allowClear={false} />
          </div>
          <div>
            <b>
              Xe ngoài:{' '}
              {!partnerSelectedId && (
                <i
                  style={{
                    color: 'red',
                    fontSize: '12px',
                  }}
                >
                  Bạn phải chọn xe ngoài
                </i>
              )}
            </b>
            <br />
            <Selection
              status={!partnerSelectedId ? 'error' : ''}
              data={partners}
              placeholder='Xe ngoài'
              fieldValue='name'
              width={400}
              value={partnerSelectedId}
              onChange={handleChangePartner}
              onSearch={getPartners}
            />
          </div>
          <div>
            <br />

            <Button
              type='primary'
              loading={loading}
              icon={<EyeOutlined />}
              style={{ padding: '0px 20px', marginLeft: '10px' }}
              disabled={!company || !summary || !detail?.length}
              onClick={exportExcelFile}
            >
              Export Excel
            </Button>
          </div>
        </Space>
      </div>
      <TableDetail dataTable={detail || []} loading={loading} />
    </>
  );
};

export { AccountantsPartnerExpense };
