import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import { ExportOutlined } from '@ant-design/icons';
import {
  fetchWaitingOfDelivery,
  updateQuery,
  updateLoadingReport,
  updateSelectedReport,
  loadMore,
} from '~store/reducers/waiting-for-delivery/action';
import { showListFile } from '~store/reducers/white-board/action';
import { TableWhiteBoard, Popup, WBTableLoadMore } from '~components';
import { getColumns } from './columns';
import { Wrapper, Title } from '~commons/styles';
import { FormBillEmptyCont } from './caroready-empty-form';
import { ChangeRomoocForm } from '../assign-car-list/change-romooc-form';
import { useWBTableScroll } from '~hooks';
import ApiServices from '~services';
import Utils from '~commons/utils';
import useApiHook from '../wb-hook/api-hook';
import { ContNumberForm } from '../assign-car-list/cont-number-form';
import { SwapContForm } from './swap-cont-form';

const WIDTH = '450px';

const INIT_FIELD = [
  {
    errors: [],
    name: ['contId'],
    value: null,
  },
];

export const WaitingOfDelivery = () => {
  const dispatch = useDispatch();
  const timeOutRef = useRef(null);
  const timeOutSetQueryRef = useRef(null);
  const formRefBillEmptyCont = useRef();
  const formRefSwapCont = useRef();

  const [loadingMore, setLoadingMore] = useState(false);
  const {
    data: waitingOfDeliveryData,
    query,
    loading,
    selected,
    selectedCargoReady,
    selectedEmptyCont,
  } = useSelector((state) => state.waitingOfDelivery);
  const [selectedCont, setSelectedCont] = useState(null);
  const [dataBillEmptyCont, setDataEmptyCont] = useState([]);
  const [changeRomooc, setChangeRomooc] = React.useState(null);
  const [contDataUpdate, setContDataUpdate] = useState();
  const [searchDate, setSearchDate] = useState(null);
  const [dataSelectSwapCont, setDataSelectSwapCont] = useState(null);

  const [dataSwapCont, setDataSwapCont] = useState(INIT_FIELD);

  const { showCustomerDetail, reloadLiftOff, reloadAfterSwapCont } = useApiHook();
  //================= Bao Xuất/ Hạ Rỗng ======================

  //================= Scroll ======================
  const { tableRef, isScroll } = useWBTableScroll(null);
  const LoadMoreData = async () => {
    if (loadingMore) return;
    if (waitingOfDeliveryData.pageNumber * query.PageSize <= waitingOfDeliveryData.totalRecords) {
      let tmpQuery = { ...query, PageNumber: waitingOfDeliveryData.pageNumber + 1 };
      setLoadingMore(true);
      await dispatch(loadMore(tmpQuery));
      setLoadingMore(false);
    }
  };
  useEffect(() => {
    if (isScroll) {
      LoadMoreData();
    }
  }, [isScroll]);

  const initData = async () => {
    dispatch(updateLoadingReport(true));
    await dispatch(fetchWaitingOfDelivery(query));
    dispatch(updateLoadingReport(false));
  };

  const noticeBookCargoReady = async (data) => {
    try {
      const result = await ApiServices.noticeBookCargoReady(data);
      if (result) {
        Utils.messageCustom('success', 'Báo Xuất thành công');
        return;
      }
    } catch (err) {
      console.error('noticeBookCargoReady', err);
      Utils.messageCustom('error', 'Báo Xuất thất bại!');
    }
  };

  const noticeBillEmptyCont = async (data) => {
    try {
      const result = await ApiServices.noticeBillEmptyCont(data);
      if (result) {
        Utils.messageCustom('success', 'Báo Hạ Rỗng thành công');
        return;
      }
    } catch (err) {
      console.error('noticeBillEmptyCont', err);
      Utils.messageCustom('error', 'Báo Hạ Rỗng thất bại!');
    }
  };

  const reportEmpty = async (contIds = [], record) => {
    setSelectedCont({ data: contIds, record, type: 'reportEmpty' });
  };

  const reportCargoReady = async (contIds = [], record) => {
    setSelectedCont({ data: contIds, record, type: 'reportCargoReady' });
  };

  const reportSingleCont = async (contId) => {
    try {
      const result = await ApiServices.createSingleCont(contId);
      if (result) {
        Utils.messageCustom('success', 'Thao tác thành công');
        initData();
      }
    } catch (err) {
      console.error('reportSingleCont', err);
      Utils.messageCustom('error', 'Thao tác thất bại!');
    }
  };

  const onHandleClickEditRomooc = (record) => {
    record.contId = record.id;
    setChangeRomooc(record);
  };

  const onSubmitFormEmptyCont = () => {
    formRefBillEmptyCont.current.submitForm();
  };
  const submitEmptyCont = async (dataSend) => {
    try {
      dispatch(updateLoadingReport(true));
      dispatch(updateSelectedReport([]));
      if (selectedCont?.type === 'reportEmpty') {
        await noticeBillEmptyCont({
          listID: selectedCont?.data,
          dateTime: dataSend?.dateTime,
          noteJob: dataSend?.note,
        });
      }
      if (selectedCont?.type === 'reportCargoReady') {
        await noticeBookCargoReady({
          listID: selectedCont?.data,
          dateTime: dataSend?.dateTime,
          noteJob: dataSend?.note,
        });
      }
      if (selectedCont?.type === '' && selectedCargoReady?.length) {
        await noticeBookCargoReady({
          listID: selectedCargoReady,
          dateTime: dataSend?.dateTime,
          noteJob: dataSend?.note,
        });
      }
      if (selectedCont?.type === '' && selectedEmptyCont?.length) {
        await noticeBillEmptyCont({
          listID: selectedEmptyCont,
          dateTime: dataSend?.dateTime,
          noteJob: dataSend?.note,
        });
      }
      await setSelectedCont(null);
      await setDataEmptyCont(null);
      await dispatch(fetchWaitingOfDelivery(query));
      reloadLiftOff();
    } catch (err) {
      console.error('Report Empty Cont', err);
    }
    dispatch(updateLoadingReport(false));
  };
  //  Swap COnt
  const onSubmitFormSwapCont = () => {
    formRefSwapCont.current.submitForm();
  };
  const submitSwapCont = async (dataSend) => {
    if (!dataSelectSwapCont || !dataSelectSwapCont?.id || !dataSend || !dataSend?.contId) {
      onCloseSwapCont();
      return;
    }
    try {
      const res = await ApiServices.swapCont(dataSelectSwapCont?.id, dataSend?.contId);
      if (res?.data?.data === 'success') {
        Utils.messageCustom('success', 'Swap Cont thành công');
        onCloseSwapCont();
        reloadAfterSwapCont();
      }
    } catch (err) {
      console.error('Swap Cont Error', err);
      Utils.messageCustom('error', 'Swap Cont thất bại!');
    }
  };

  useEffect(() => {
    clearTimeout(timeOutRef.current);
    timeOutRef.current = setTimeout(() => initData(), 50);
  }, [query]);

  const setQuery = (columns) => {
    const tmpQuery = { ...query };
    tmpQuery.Columns = { ...tmpQuery.Columns, ...columns };

    clearTimeout(timeOutSetQueryRef.current);
    timeOutSetQueryRef.current = setTimeout(() => dispatch(updateQuery(tmpQuery)), 250);
  };

  const onSelectChange = (selectedRowKey, selectedRows) => {
    dispatch(updateSelectedReport(selectedRows));
  };
  const setLoading = (val) => dispatch(updateLoadingReport(val));
  const showOrderFiles = (record) => {
    dispatch(showListFile(record));
  };
  const showContForm = (record) => {
    setContDataUpdate({
      contFiles: record?.contFiles,
      contNumber: record?.contNumber,
      sealNumber: record?.sealNumber,
      contId: record?.id || record?.contId,
    });
  };
  const onCloseContForm = () => setContDataUpdate();

  const showSwapCont = (record) => {
    setDataSwapCont(INIT_FIELD);
    setDataSelectSwapCont(record);
  };
  const onCloseSwapCont = () => {
    setDataSelectSwapCont(null);
  };

  const countAssigned =
    waitingOfDeliveryData?.totalRecords -
    waitingOfDeliveryData?.countBillNotNotice -
    waitingOfDeliveryData?.countBookNotNotice;

  const renderRowClassName = (record) =>
    ` ${
      record.book_CargoReadyDate || record.bill_EmptyContDate
        ? 'row-assigned'
        : Utils.renderTypeOrderBackground(record?.orderType)
    } ${record.orderType}`;

  const checkDisableSelection = (record) =>
    record.book_CargoReadyDate || record.bill_EmptyContDate || !record?.yes?.length;
  const titleCarOready =
    selectedCont?.type === 'reportEmpty'
      ? 'Báo Hạ Rỗng'
      : selectedCont?.type === 'reportCargoReady'
      ? 'Báo Xuất'
      : 'Báo Xuất/ Hạ Rỗng';
  const titlePopup = (
    <>
      {titleCarOready}
      <span className='blue'> {selectedCont?.record?.subnoNumber}</span>
    </>
  );

  const handleChangeSearchDate = (dateValue) => {
    setSearchDate(dateValue);
    const dataSearch = dateValue ? moment(dateValue).format('DD/MM') : '';
    setQuery({ allDate: dataSearch });
  };

  return (
    <>
      <Wrapper width={WIDTH}>
        <div style={{ display: 'flex' }}>
          <Title>
            Báo xuất/ Hạ rỗng <b className='red'>({waitingOfDeliveryData?.totalRecords})</b>
            {!!countAssigned && <b>({countAssigned})</b>}
          </Title>
          <Button
            type='primary'
            style={{ marginLeft: 'auto' }}
            shape='round'
            icon={<ExportOutlined />}
            disabled={!selected?.length}
            loading={loading}
            onClick={() => setSelectedCont({ type: '' })}
          >
            Báo Xuất/ Hạ Rỗng
          </Button>
        </div>
        <TableWhiteBoard
          tableRef={tableRef}
          rowKey='id'
          headerLength='190px'
          columns={getColumns(
            setQuery,
            reportEmpty,
            reportCargoReady,
            showOrderFiles,
            onHandleClickEditRomooc,
            showContForm,
            showCustomerDetail,
            reportSingleCont,
            searchDate,
            handleChangeSearchDate,
            showSwapCont
          )}
          data={waitingOfDeliveryData?.data}
          loading={loading}
          onSelectChange={onSelectChange}
          selectedParent={selected}
          checkDisableSelection={checkDisableSelection}
          rowClassName={renderRowClassName}
        />
        <WBTableLoadMore loading={loadingMore} />
      </Wrapper>
      <ContNumberForm
        cont={contDataUpdate}
        isUpdateContNumber
        reload={initData}
        onClose={onCloseContForm}
        setLoading={setLoading}
      />
      <Popup
        width={500}
        title={titlePopup}
        nameButton={titleCarOready}
        visible={!!selectedCont}
        closeModel={() => onCloseSwapCont(null)}
        onSubmit={() => onSubmitFormEmptyCont()}
        body={
          <FormBillEmptyCont
            ref={formRefBillEmptyCont}
            fields={dataBillEmptyCont}
            selectedCont={selectedCont}
            onChange={setDataEmptyCont}
            onClosePopup={() => setSelectedCont(null)}
            onSubmit={submitEmptyCont}
          />
        }
      />
      <Popup
        width={500}
        title='Swap Cont'
        nameButton='Xác nhận Swap Cont'
        visible={!!dataSelectSwapCont}
        closeModel={onCloseSwapCont}
        onSubmit={onSubmitFormSwapCont}
        body={
          <SwapContForm
            ref={formRefSwapCont}
            fields={dataSwapCont}
            selectedCont={dataSelectSwapCont}
            onChange={setDataSwapCont}
            onClosePopup={onCloseSwapCont}
            onSubmit={submitSwapCont}
          />
        }
      />
      <ChangeRomoocForm
        job={changeRomooc}
        onClose={() => setChangeRomooc(null)}
        setLoading={setLoading}
      />
    </>
  );
};
