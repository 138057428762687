import { useEffect, useRef, useState, useCallback } from 'react';
import moment from 'moment';
import { debounce } from 'lodash';
import { Button, Input, Tooltip, Space, Select, DatePicker, Checkbox } from 'antd';
import { QuestionCircleOutlined, FormOutlined, DownloadOutlined } from '@ant-design/icons';
import Utils from '~commons/utils';
import { TableLayout } from '~components';
import { RangeDateFilter } from '~components';
import ApiServices from '~services';
import { FormInput } from './form-input';
import { ContNumberFormForSearchCont } from '../white-board/assign-car-list/cont-number-form-for-search-cont';
import { ContFiles } from '~components/cont-files';
import { TableTitle, Wrapper } from '~commons/styles';
import { DATE_FORMAT, DATE_FORMAT_SEND_API } from '~commons/constants';

const DEFAULT_QUERIES = {
  PageSize: 10,
  PageNumber: 1,
  DateFrom: moment().subtract(1, 'days').startOf('date').format('YYYY-MM-DD hh:mm'),
  DateTo: moment().endOf('date').format('YYYY-MM-DD hh:mm'),
};
const objSortType = {
  ascend: 'ASC',
  descend: 'DESC',
};

const ORDERS_OPTION_SELECTS = [
  {
    label: 'Tất cả',
    value: '',
  },
  {
    label: 'Booking',
    value: 'Booking',
  },
  {
    label: 'Bill',
    value: 'Bill',
  },
];
const { Option } = Select;

export const ContSearchNumber = ({ headerLength = '190px' }) => {
  const formRef = useRef();
  const [loading, setLoading] = useState(false);
  const [dataContList, setDataContList] = useState([]);
  const [selected, setSelected] = useState();
  const [contDataUpdate, setContDataUpdate] = useState();
  const [filter, setFilter] = useState(DEFAULT_QUERIES);
  const [orderType, setOrderType] = useState('');
  const [checked, setChecked] = useState(false);

  const [bookCutOffTimeSearch, setBookCutOffTimeSearch] = useState(null);
  const [bookSIDateSearch, setBookSIDateSearch] = useState(null);
  const [bookLiftingEmptyContRequestDateSearch, setBookLiftingEmptyContRequestDateSearch] =
    useState(null);
  const [dateUpSearch, setDateUpSearch] = useState(null);
  const [createdOnSearch, setCreatedOnSearch] = useState(null);

  const getData = async (query = DEFAULT_QUERIES, isSearch = false) => {
    setLoading(true);
    let newQuery = {};
    if (isSearch) {
      newQuery = {
        ...query,
        DateFrom: query?.DateFrom && moment(query?.DateFrom).format('YYYY-MM-DD'),
        DateTo: query?.DateTo && moment(query?.DateTo).format('YYYY-MM-DD'),
      };
    } else {
      newQuery = {
        ...query,
        DateFrom: null,
        DateTo: null,
        PageSize: query?.PageSize,
        PageNumber: query?.PageNumber,
      };
    }

    return ApiServices.searchContNumber(newQuery)
      .then((res) => {
        if (res?.data) {
          setDataContList(res?.data);
        }
      })
      .catch((err) => {
        console.error('Get cont list Error', err);
      })
      .finally(() => setLoading(false));
  };

  const onColumnSearch = (event, select = '') => {
    setFilter((prev) => {
      const Columns = { ...prev.Columns };
      const value = select ? event : event.target.value;
      const name = select ? select : event.target.name;
      if (!value) {
        delete Columns[name];
        return { ...prev, Columns, PageNumber: 1 };
      }
      Columns[name] = value;
      return { ...prev, Columns, PageNumber: 1 };
    });
  };

  const timeoutGetData = useCallback(
    debounce((newFilter, isSearch) => {
      getData(newFilter, isSearch);
    }, 500),
    []
  );
  useEffect(() => {
    timeoutGetData(filter, checked);
  }, [filter, checked]);

  const handleChangeBookCutOffTimeSearch = (date) => {
    setBookCutOffTimeSearch(date);
    const dataSearch = date ? moment(date).format(DATE_FORMAT_SEND_API) : null;
    onColumnSearch(dataSearch, 'book_CutOffDateTime');
  };
  const handleChangeBookSIDateSearch = (date) => {
    setBookSIDateSearch(date);
    const dataSearch = date ? moment(date).format(DATE_FORMAT_SEND_API) : null;
    onColumnSearch(dataSearch, 'book_SIDateTime');
  };
  const handleChangeBookLiftingEmptyContRequestDateSearch = (date) => {
    setBookLiftingEmptyContRequestDateSearch(date);
    const dataSearch = date ? moment(date).format(DATE_FORMAT_SEND_API) : null;
    onColumnSearch(dataSearch, 'book_LiftingEmptyContRequestDate');
  };
  const handleChangeDateUpSearch = (date) => {
    setDateUpSearch(date);
    const dataSearch = date ? moment(date).format(DATE_FORMAT_SEND_API) : null;
    onColumnSearch(dataSearch, 'dateUpReal');
  };

  const handleChangeCreateOnSearch = (date) => {
    setCreatedOnSearch(date);
    const dataSearch = date ? moment(date).format(DATE_FORMAT_SEND_API) : null;
    onColumnSearch(dataSearch, 'createdOn');
  };

  const showContForm = (record) => {
    setContDataUpdate({
      contFiles: record?.contFiles,
      contNumber: record?.contNumber,
      sealNumber: record?.sealNumber,
      contId: record?.id || record?.contId,
    });
  };
  const onCloseContForm = () => {
    setContDataUpdate();
  };

  const renderBookingBill = (value, record) => {
    return (
      <div>
        <div>{value && Utils.formatDate(value)}</div>
        <div
          style={{
            fontSize: '12px',
          }}
        >
          {record?.files?.length && <ContFiles files={record?.files} />}
        </div>
      </div>
    );
  };

  const columns = [
    {
      title: <TableTitle> Ngày Đơn Hàng</TableTitle>,
      sorter: true,
      dataIndex: 'createdOn',
      showSorterTooltip: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      children: [
        {
          title: (
            <TableTitle>
              <DatePicker
                value={createdOnSearch}
                onChange={handleChangeCreateOnSearch}
                placeholder='Search'
                style={{ width: '100%', fontSize: '10px' }}
                format={DATE_FORMAT}
                allowClear={true}
              />
            </TableTitle>
          ),
          dataIndex: 'createdOn',
          align: 'center',
          width: 130,
          render: renderBookingBill,
        },
      ],
    },
    {
      title: <TableTitle>SubNo</TableTitle>,
      sorter: true,
      dataIndex: 'subnoNumber',
      showSorterTooltip: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      children: [
        {
          title: (
            <TableTitle>
              <Input name='subnoNumber' onChange={onColumnSearch} placeholder='Search' />
            </TableTitle>
          ),
          dataIndex: 'subnoNumber',
          align: 'center',
          width: 150,
        },
      ],
    },
    {
      title: <TableTitle>Khách Hàng</TableTitle>,
      sorter: true,
      dataIndex: 'customerName',
      showSorterTooltip: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      children: [
        {
          title: (
            <TableTitle>
              <Input name='customerName' onChange={onColumnSearch} placeholder='Search' />
            </TableTitle>
          ),
          dataIndex: 'customerName',
          align: 'center',
          width: 150,
        },
      ],
    },
    {
      title: <TableTitle>Số Cont</TableTitle>,
      sorter: true,
      dataIndex: 'contNumber',
      showSorterTooltip: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      children: [
        {
          title: (
            <TableTitle>
              <Input name='contNumber' onChange={onColumnSearch} placeholder='Search' />
            </TableTitle>
          ),
          width: 120,
          dataIndex: 'contNumber',
          align: 'center',
          render: (val, record) => (
            <div>
              <div
                style={{
                  marginBottom: '8px',
                }}
              >
                {val}
              </div>
              <div
                style={{
                  margin: 'auto',
                }}
              >
                <Tooltip title='Cập nhật số cont'>
                  <Button
                    type='link'
                    icon={<FormOutlined />}
                    onClick={() => showContForm(record)}
                  ></Button>
                </Tooltip>
              </div>
            </div>
          ),
        },
      ],
    },
    {
      title: <TableTitle>Số Seal</TableTitle>,
      sorter: true,
      dataIndex: 'sealNumber',
      showSorterTooltip: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      children: [
        {
          title: (
            <TableTitle>
              <Input name='sealNumber' onChange={onColumnSearch} placeholder='Search' />
            </TableTitle>
          ),
          dataIndex: 'sealNumber',
          align: 'center',
          width: 120,
        },
      ],
    },
    {
      title: <TableTitle>File Đính kèm</TableTitle>,
      dataIndex: 'contFiles',
      width: 320,
      render: (val) => <ContFiles files={val} />,
    },
    {
      title: (
        <TableTitle>
          Xe Lấy
          <Input name='trace' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'carUp',
      align: 'center',
      width: 130,
    },
    {
      title: (
        <TableTitle>
          <Tooltip
            title={
              <div>
                Ngày LR/LH:
                <div>* LR - Ngày Lấy rỗng (Book)</div>
                <div>* LH - Ngày Lấy hàng (Bill)</div>
              </div>
            }
            color='#108ee9'
          >
            Ngày LR/LH <QuestionCircleOutlined style={{ color: 'orange', cursor: 'pointer' }} />
          </Tooltip>
          <DatePicker
            value={dateUpSearch}
            onChange={handleChangeDateUpSearch}
            placeholder='Search'
            style={{ width: '100%', fontSize: '10px' }}
            format={DATE_FORMAT}
            allowClear={true}
          />
        </TableTitle>
      ),
      dataIndex: 'dateUpReal',
      align: 'center',
      width: 120,
      render: (val) => Utils.formatDate(val),
    },
    {
      title: (
        <TableTitle>
          <Tooltip
            title={
              <div>
                Nơi LR/LH:
                <div>* LR - Nơi Lấy rỗng (Book)</div>
                <div>* LH - Nơi Lấy hàng (Bill)</div>
              </div>
            }
            color='#108ee9'
          >
            Nơi LR/LH <QuestionCircleOutlined style={{ color: 'orange', cursor: 'pointer' }} />
          </Tooltip>
          <Input name='LH' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'lh',
      align: 'center',
      width: 150,
    },
    {
      title: (
        <TableTitle>
          Xe Hạ
          <Input name='trace' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'carDown',
      align: 'center',
      width: 130,
    },
    {
      title: (
        <TableTitle>
          <Tooltip
            title={
              <div>
                Ngày HR/HH:
                <div>* HR - Ngày Hạ rỗng (Book)</div>
                <div>* HH - Ngày Hạ hàng (Bill)</div>
              </div>
            }
            color='#108ee9'
          >
            Ngày HR/HH <QuestionCircleOutlined style={{ color: 'orange', cursor: 'pointer' }} />
          </Tooltip>
        </TableTitle>
      ),
      dataIndex: 'dateDown',
      align: 'center',
      width: 120,
      render: (val) => Utils.formatDate(val),
    },
    {
      title: (
        <TableTitle>
          <Tooltip
            title={
              <div>
                Nơi HR/HH:
                <div>* HR - Nơi Hạ rỗng (Book)</div>
                <div>* HH - Nơi Hạ hàng (Bill)</div>
              </div>
            }
            color='#108ee9'
          >
            Nơi HR/HH <QuestionCircleOutlined style={{ color: 'orange', cursor: 'pointer' }} />
          </Tooltip>
          <Input name='GH' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'gh',
      align: 'center',
      width: 150,
    },
    {
      title: (
        <TableTitle>
          Loại Cont
          <Input name='typeContName' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'typeContName',
      align: 'center',
      width: 120,
    },
    {
      title: (
        <TableTitle>
          Cắt Máng
          <DatePicker
            value={bookCutOffTimeSearch}
            onChange={handleChangeBookCutOffTimeSearch}
            placeholder='Search'
            style={{ width: '100%', fontSize: '10px' }}
            format={DATE_FORMAT}
            allowClear={true}
          />
        </TableTitle>
      ),
      dataIndex: 'book_CutOffDateTime',
      align: 'center',
      width: 120,
      render: (val) => Utils.formatDate(val),
    },
    {
      title: (
        <TableTitle>
          Cắt SI
          <DatePicker
            value={bookSIDateSearch}
            onChange={handleChangeBookSIDateSearch}
            placeholder='Search'
            style={{ width: '100%', fontSize: '10px' }}
            format={DATE_FORMAT}
            allowClear={true}
          />
        </TableTitle>
      ),
      dataIndex: 'book_SIDateTime',
      align: 'center',
      width: 120,
      render: (val) => Utils.formatDate(val),
    },
    {
      title: (
        <TableTitle>
          <Tooltip title='Ngày YCLC: Ngày yêu cầu giao công' color='#108ee9'>
            Ngày YCGC <QuestionCircleOutlined style={{ color: 'orange', cursor: 'pointer' }} />
          </Tooltip>
          <DatePicker
            value={bookLiftingEmptyContRequestDateSearch}
            onChange={handleChangeBookLiftingEmptyContRequestDateSearch}
            placeholder='Search'
            style={{ width: '100%', fontSize: '10px' }}
            format={DATE_FORMAT}
            allowClear={true}
          />
        </TableTitle>
      ),
      dataIndex: 'book_LiftingEmptyContRequestDate',
      align: 'center',
      width: 120,
      render: (val) => Utils.formatDate(val),
    },
    {
      title: <TableTitle>Loại ĐH</TableTitle>,
      dataIndex: 'typeOrder',
      align: 'center',
      width: 100,
    },
    // {
    //   title: (
    //     <TableTitle>
    //       Romooc
    //       <Input name='romooc' onChange={onColumnSearch} placeholder='Search' />
    //     </TableTitle>
    //   ),
    //   dataIndex: 'romooc',
    //   align: 'center',
    //   width: 120,
    // },

    // {
    //   title: (
    //     <TableTitle>
    //       Nơi lấy hàng
    //       <Input name='LH' onChange={onColumnSearch} placeholder='Search' />
    //     </TableTitle>
    //   ),
    //   dataIndex: 'lh',
    //   align: 'center',
    //   width: 150,
    // },
    // {
    //   title: (
    //     <TableTitle>
    //       Nơi giao hàng
    //       <Input name='GH' onChange={onColumnSearch} placeholder='Search' />
    //     </TableTitle>
    //   ),
    //   dataIndex: 'gh',
    //   align: 'center',
    //   width: 150,
    // },
  ];
  const onCloseEditTab = () => setSelected();

  const pagination = {
    total: dataContList?.totalRecords || 0,
    showSizeChanger: true,
    pageSize: filter.PageSize,
    current: filter.PageNumber,
    onChange: (PageNumber, PageSize) =>
      setFilter((prev) => ({
        ...prev,
        PageNumber,
        PageSize,
      })),
  };

  const handleChangeOrderType = (value) => {
    console.log({ value });
    setOrderType(value);
    onColumnSearch(value, 'typeOrder');
  };

  const onChangeCheckboxToSearchDate = (e) => {
    console.log('checked = ', e.target.checked);
    setChecked(e.target.checked);
  };

  const handleExportExcel = async (query) => {
    if (loading) return;
    let dataSend = {};
    if (checked) {
      dataSend = {
        DateFrom: query?.DateFrom && moment(query?.DateFrom).format('YYYY-MM-DD'),
        DateTo: query?.DateTo && moment(query?.DateTo).format('YYYY-MM-DD'),
        Columns: query?.Columns,
        export: true,
      };
    } else {
      dataSend = { Columns: query?.Columns, export: true };
    }
    setLoading(true);
    try {
      const { data } = await ApiServices.exportExcelContSearchNumber(dataSend);
      if (data) {
        const outputFilename = `Danh_Sach_So_Cont.xlsx`;
        // If you want to download file automatically using link attribute.
        const url = URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        window.TmsNoti?.error('Export thất bại!', 'Không có dữ liệu');
      }
    } catch (err) {
      window.TmsNoti?.error('Export thất bại!');
    } finally {
      setLoading(false);
    }
  };

  const headerContent = (
    <Space>
      <Select
        placeholder='Search'
        style={{ width: 110 }}
        defaultValue=''
        value={orderType}
        onChange={handleChangeOrderType}
      >
        {ORDERS_OPTION_SELECTS.map((item) => (
          <Option key={item.label + item.value} value={item.value}>
            {item.label}
          </Option>
        ))}
      </Select>
      <Checkbox checked={checked} onChange={onChangeCheckboxToSearchDate}>
        Seach Từ ngày đến ngày
      </Checkbox>
      {checked && (
        <>
          <RangeDateFilter query={filter} onChange={setFilter} />
          <Tooltip
            title={
              <div>
                <div>* Booking -{'>'} Ngày Lấy Rỗng</div>
                <div>* Bill -{'>'} Ngày Lấy Hàng</div>
              </div>
            }
            color='#108ee9'
          >
            <QuestionCircleOutlined
              style={{ color: 'orange', cursor: 'pointer', fontSize: '20px' }}
            />
          </Tooltip>
        </>
      )}
      <Button
        type='primary'
        icon={<DownloadOutlined />}
        style={{ padding: '0px 20px', marginLeft: '10px' }}
        onClick={() => handleExportExcel(filter)}
      >
        Export Excel
      </Button>
    </Space>
  );

  const renderRowClassName = (record) => {
    if (record?.typeOrder === 'Bill') {
      return 'red';
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    console.log({ pagination, filters, sorter });
    if (sorter?.field && sorter?.order) {
      setFilter((prev) => ({
        ...prev,
        SortColumn: sorter?.field,
        SortColumnDirection: objSortType[sorter?.order],
      }));
    }
  };

  return (
    <Wrapper>
      <TableLayout
        rowKey='contId'
        columns={columns}
        data={dataContList?.data}
        loading={loading}
        titleTab={selected?.jobName}
        headerContent={headerContent}
        headerLength={headerLength}
        tabPanels={[
          {
            title: 'Cập nhật thông tin',
            content: (
              <FormInput
                ref={formRef}
                dataInput={selected}
                reload={() => timeoutGetData(filter)}
                onClose={onCloseEditTab}
              />
            ),
          },
        ]}
        closeDrawer={onCloseEditTab}
        selected={selected}
        pagination={pagination}
        isDisplayCheckbox={false}
        rowClassName={renderRowClassName}
        onChange={handleTableChange}
      />
      <ContNumberFormForSearchCont
        cont={contDataUpdate}
        isUpdateContNumber
        reload={() => timeoutGetData(filter)}
        onClose={onCloseContForm}
        setLoading={setLoading}
      />
    </Wrapper>
  );
};
