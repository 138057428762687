import Utils from '~commons/utils';
export const COLUMNS = [
  {
    title: 'STT',
    with: 40,
    dataIndex: 'index',
    align: 'center',
  },
  {
    title: 'Ngày',
    with: 100,
    dataIndex: 'assignDate',
    align: 'center',
    render: (value) => Utils.formatDate(value),
  },
  {
    title: 'Booking / Bill',
    with: 150,
    dataIndex: 'subnoNumber',
    align: 'center',
  },
  {
    title: 'Xe',
    with: 60,
    dataIndex: 'carCode',
    align: 'center',
  },
  {
    title: 'Nơi Lấy',
    with: 100,
    dataIndex: 'up',
    align: 'center',
  },
  {
    title: 'Nơi Hạ',
    with: 100,
    dataIndex: 'down',
    align: 'center',
  },
  {
    title: 'Số Cont',
    with: 100,
    dataIndex: 'contNumber',
    align: 'center',
  },
];
export const EXPORT_HEADER = [
  {
    index: 'STT',
    date: 'Ngày',
    totalUp: 'Lấy',
    totalDown: 'Hạ',
    total: 'Số Tour',
    totalTransfer: 'Số Tour Ck',
  },
];
export const MERGE_COLUMN_SHEET = [];
