import React, { useEffect, useState } from 'react';
import { Form, InputNumber, Button, message, Select } from 'antd';
import ApiServices from '~services';
import { Selection } from '~components';
import { layout, tailLayout } from './constant';

const { Option } = Select;

const FormData = ({
  fields,
  isNew,
  id,
  reload,
  onChange,
  setLoading,
  idStartLocation,
  nameStartLocation,
  idEndLocation,
  nameEndLocation,
}) => {
  const [locations, setLocations] = React.useState([]);
  const [companies, setCompanies] = useState([]);

  const getLocations = async (Query = null) => {
    let { data } = await ApiServices.getLocations({ Query });
    const dataNew = data.data;
    if (idStartLocation && Query === null) {
      const ind = dataNew.findIndex((item) => item.id === idStartLocation);
      if (ind === -1) {
        dataNew.push({ id: idStartLocation, name: nameStartLocation });
      }
    }
    if (idEndLocation && Query === null) {
      const ind = dataNew.findIndex((item) => item.id === idEndLocation);
      if (ind === -1) {
        dataNew.push({ id: idEndLocation, name: nameEndLocation });
      }
    }

    setLocations(dataNew);
  };

  useEffect(() => {
    getLocations();
  }, [idStartLocation, idEndLocation]);

  const getDataInit = async () => {
    const [getCompany] = await Promise.all([ApiServices.getCompanies()]);
    setCompanies(getCompany?.data?.data);
  };

  useEffect(() => {
    getDataInit();
  }, []);

  const createDistance = async (data) => {
    try {
      const res = await ApiServices.createDistance(data);
      if (res.data) {
        message.success('Thêm mới Cự Ly thành công');
        return reload();
      } else {
        message.error('Thêm mới Cự Ly thất baị, vui lòng kiểm tra lại thông tin');
      }
    } catch (err) {
      message.error('Thêm mới Cự Ly thất baị, vui lòng kiểm tra lại thông tin');
      console.error(err);
    }
  };

  const updateDistance = async (data) => {
    data.id = id;
    try {
      const res = await ApiServices.updateDistance(id, data);
      if (res.data) {
        message.success('Cập nhật Cự Ly thành công');
        return reload();
      } else {
        message.error('Cập nhật Cự Ly thất baị, vui lòng kiểm tra lại thông tin');
      }
    } catch (err) {
      message.error('Cập nhật Cự Ly thất baị, vui lòng kiểm tra lại thông tin');
      console.error(err);
    }
  };

  const onSubmitForm = async (value) => {
    if (isNew) {
      setLoading(true);
      await createDistance(value);
      setLoading(false);
      return;
    }
    setLoading(true);
    await updateDistance(value);
    setLoading(false);
  };

  const locationSelectDiv = (
    <Selection
      data={locations}
      placeholder='Chọn Địa Điểm'
      fieldValue='name'
      onSearch={getLocations}
    />
  );

  return (
    <Form
      {...layout}
      name='global_state'
      onFinish={onSubmitForm}
      fields={fields}
      onFieldsChange={(_, allFields) => {
        onChange(allFields);
      }}
    >
      <Form.Item
        name='locationFromCode'
        label='Địa điểm bắt đầu'
        rules={[
          {
            required: true,
            message: 'Bạn phải nhập Địa điểm bắt đầu',
          },
        ]}
      >
        {locationSelectDiv}
      </Form.Item>
      <Form.Item
        name='locationToCode'
        label='Địa điểm kết thúc'
        rules={[
          {
            required: true,
            message: 'Bạn phải nhập Địa điểm kết thúc',
          },
        ]}
      >
        {locationSelectDiv}
      </Form.Item>

      <Form.Item
        name='distanceKm'
        label='Khoảng cách (KM)'
        rules={[
          {
            required: true,
            message: 'Bạn phải nhập Khoảng cách',
          },
        ]}
      >
        <InputNumber placeholder='Khoảng cách' style={{ width: '100%' }} min={0} />
      </Form.Item>

      <Form.Item
        name='loss'
        label='Hao phí'
        rules={[
          {
            required: true,
            message: 'Bạn phải nhập Hao phí',
          },
        ]}
      >
        <InputNumber placeholder='Hao phí' style={{ width: '100%' }} min={0} />
      </Form.Item>
      <Form.Item
        name='companyId'
        label='Công Ty'
        rules={[
          {
            required: true,
            message: 'Bạn phải chọn Công Ty',
          },
        ]}
      >
        <Select showSearch placeholder='Lựa Chọn Công Ty'>
          {companies.map(({ id, name }) => (
            <Option key={id} value={id}>
              {name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item {...tailLayout}>
        <Button type='primary' htmlType='submit'>
          {isNew ? 'Thêm Mới' : 'Cập nhật'}
        </Button>
      </Form.Item>
    </Form>
  );
};

export { FormData };
